<template>
  <div class="knw_message_body"
    :class="[getMessageSender, {
      'is-first': sequenz === 'start',
      'is-last':  sequenz === 'end',
      'is-single': sequenz === 'single' }]">
    <span>
      {{message.data.text}}
    </span>
  </div>
</template>

<script>
export default {
  name: 'UserMessage',
  props: ['message', 'sequenz'],
  components: {},
  computed: {
    getMessageSender() {
      return `sender_${this.message.source || 'bot'}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.knw_message_body{
  margin-bottom: 3px;
  display: flex;
  width: 100%;
  padding-left: 85px;
  position: relative;
  span{
    margin-left: 15px;
    display: inline-flex;
    border-radius: var(--kw-widget-global-borderradius-inner);
    background: darkblue;
    color: white;
    padding: 10px 18px 10px 12px;
    min-width: 30px;
    @include font_chat_text;
  }

  &.sender_user{
    justify-content: flex-end;
    span{
      background-color: var(--kw-messageTypes-userMessage-background);
      color: var(--kw-messageTypes-userMessage-textcolor);
    }
  }

  &.is-first, &.is-single {
    margin-top: 14px;
  }

  &.is-last, &.is-single{
    margin-bottom: 12px;
    span{
      border-bottom-right-radius: 0px;
    }

    &:after{
      position: absolute;
      content: '';
      display: block;
      bottom: -10px;
      right: 0;
      width: 10px;
      height: 10px;
      background-color: var(--kw-messageTypes-userMessage-background);
      /* The points are: centered top, left bottom, right bottom */
      clip-path: polygon(100% 0, 0 0, 100% 80%);
    }
  }
}

</style>
