<template>
  <div id="knw_widget_input" class="knw_widget_input">
    <!-- TOAST (INPUT) STACK -->
    <transition name="slide_up">
      <Toast />
    </transition>
    <!-- SECONDARY INPUT STACK -->
    <!-- QR, MASKED INPUT, ... -->
    <transition name="slide_up">
      <QuickReplies v-if="showQuickReplies" key="quickreplies" :quickReplies="widgetStore.widget.qr" />
    </transition>
    <!-- PRIMARY INPUT STACK -->
    <!-- TEXTINPUT OR CANCEL ACTION FO SECONDARY / SPECIALIZED INPUT (MASKED INPUT / QR / ...) -->
    <transition name="switchinput" mode="out-in">
      <div>
        <TextInput maxCharCount="256" v-if="getInputType === 'textinput'" key="textinput" />
        <CancelButton v-if="getInputType === 'cancel'" key="blocked_cancel" />
      </div>
    </transition>
  </div>
</template>
<script>
import TextInput from "@/components/inputs/TextInput.vue";
import CancelButton from "@/components/inputs/CancelButton.vue";
import QuickReplies from "@/components/inputs/QuickReplies.vue";
import Toast from "@/components/notifications/Toast.vue";
import { mapStores, mapState } from 'pinia'
import { useWidgetStore } from '@/store/widget'

export default {
  name: "WidgetInput",
  data() {
    return {};
  },
  components: {
    TextInput,
    CancelButton,
    QuickReplies,
    Toast
  },
  computed: {
    ...mapStores(useWidgetStore),
    ...mapState(useWidgetStore, ['getInputType']),
    showQuickReplies() {
      return this.widgetStore.widget.qr.length !== 0;
    }
  },
  methods: {
    measureLineCount() {
      const textarea = this.$refs.textinput;
      const h1 = textarea.scrollHeight;
      this.linecount = Math.ceil(h1 / 18);
    }
  }
};
</script>

<style lang="scss" scoped>
.knw_widget_input {
  box-shadow: 0px 20px 20px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  height: auto;
  width: 100%;
  justify-content: flex-end;
  min-height: 50px;
  max-height: 450px;
  z-index: 90;
  // padding: 5px 0 5px 0;
  background-color: $kw-global-background-input;
  transition: height $kw-default-transition-speed ease-out;
  // &:before {
  //   pointer-events: none;
  //   content:'';
  //   position: absolute;
  //   width: 100%;
  //   height: 30px;
  //   background: linear-gradient(to top, $kw-global-background-input, transparent);
  //   top: -30px;
  //   left: 0;
  // }
}
.knw_widget_inputbar {
  position: relative;
  min-height: 50px;
  //padding: 10px 0;
  z-index: 100;
  //transform: translateX(0);
  background: none;
  padding: 5px 8px;
  textarea {
    padding: 0 14px;
    border: none;
    // background: $kw-global-background-white;
    background-color: $kw-global-background-input;
    background-color: green;
    resize: none;
    width: 100%;
    height: auto;
    outline: none;
    font: 16px "Rubik", "Helvetica", "Arial", sans-serif;
    color: #333;

    &::placeholder {
      color: #aaaaaa;
    }
  }

  .knw_widget_inputbar_btn {
    width: 35px;
    height: 35px;
    background: transparent;
    border: none;
    padding: 0;
    margin-right: 8px;
    font-size: 16px;
  }

  > svg {
    width: 35px;
    height: 35px;
    max-width: 35px;
    max-height: 35px;
    display: block;
  }
}

// slide up (quick replies)
.slide_up-enter {
  height: 0 !important;
  opacity: 0;
}
.slide_up-enter-active {
  transition: height 0.4s ease-in-out,
    opacity $kw-default-transition-speed ease-in-out $kw-default-transition-delay;
}
.slide_up-enter-to {
  opacity: 1;
}

.slide_up-leave {
  opacity: 1;
}
.slide_up-leave-active {
  transition: height 0.5s ease-in-out, opacity $kw-default-transition-speed ease-in-out;
}
.slide_up-leave-to {
  opacity: 0;
  height: 0 !important;
}

// switch input
.switchinput-enter {
  opacity: 0;
  transform: translateY(50px);
}

.switchinput-enter-active {
  transition: all 0.2s ease-out;
}
.switchinput-enter-to {
  transform: translateY(0px);
  opacity: 1;
}

.switchinput-leave {
  opacity: 1;
  transform: translateY(0px);
}
.switchinput-leave-active {
  transition: all 0.2s ease-out;
}
.switchinput-leave-to {
  opacity: 0;
  /*  transform: translateY(50px);*/
}
</style>
