<template>
  <transition name="textmessage_appear">
    <div
      class="knw_message_body"
      :class="[getMessageSender,
      {
        'is-first': sequenz === 'first',
        'is-start': sequenz === 'start',
        'is-end': sequenz === 'end',
        'is-single': sequenz === 'single'
      }]"
    >
      <Avatar
        v-if="showAvatar"
        :image="getAvatarUrl(message)"
        width="48px"
        height="48px"
        border="2"
      />
      <span>{{ message.data.text }}</span>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'pinia'
import { useWidgetStore } from '@/store/widget'
import Avatar from '@/components/Avatar.vue';

export default {
  name: 'TextMessage',
  props: ['message', 'sequenz'],
  components: {
    Avatar,
  },
  computed: {
    showAvatar() {
      return this.sequenz === 'start' || this.sequenz === 'single';
    },
    getMessageSender() {
      return `sender_${this.message.source || 'bot'}`;
    },
    ...mapState(useWidgetStore, ['getAvatarByOperatorID'])
  },
  methods: {
    getAvatarUrl(message) {
      const op = message.operator || 'default';
      return this.getAvatarByOperatorID(op)
    },
  },
};
</script>

<style lang="scss" scoped>
.knw_message_body {
  margin-bottom: 7px;
  display: flex;
  width: 100%;
  // minimal distance to right side of the widget
  padding-right: 35px;
  position: relative;
  padding-left: 35px;
  span {
    white-space: pre-line;
    margin-left: 22px;
    display: inline-flex;
    padding: 10px 12px 10px 12px;
    border-radius: var(--kw-widget-global-borderradius-inner);
    @include font_chat_text;
    background: var(--kw-messageTypes-textMessage-background);
    color: var(--kw-messageTypes-textMessage-textcolor);
  }

  .avatar {
    position: absolute;
    left: 0px;
    top: -5px;
    // border-radius: 50%;
    border-radius: var(--kw-widget-global-borderradius-shape);
  }

  &.is-start {
  }
  &.is-start,
  &.is-single,
  &.is-first {
    margin-top: 10px;
    span {
      border-top-left-radius: 0px;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        left: -7px;
        width: 10px;
        height: 10px;
        background: var(--kw-messageTypes-textMessage-background);
        /* The points are: centered top, left bottom, right bottom */
        clip-path: polygon(100% 0, 0 0, 100% 100%);
      }
    }
  }

  &.sender_user {
    justify-content: flex-end;
    span {
      border-radius: 10px 0 10px 10px;
      background-color: var(--kw-message-user-background);
    }
  }
}

/* TEXT MESSAGE APPEAR */
.textmessage_appear-enter {
  opacity: 0;
  transform: translateX(-100px);
}

.textmessage_appear-enter-active {
  transition: transform 0.5s;
  transition: all 1s linear;
}
.textmessage_appear-enter-to {
  transform: translateX(0px);
  opacity: 1;
}
.textmessage_appear-leave {
  transform: translateX(0px);
}
.textmessage_appear-leave-active {
  transition: transform 0.5s;
}
.textmessage_appear-leave-to {
  transform: translateX(-100px);
}
</style>
