<template>
  <div class="knw_widget_inputbar_cancel">
    <transition name="delayed_appear" appear>
      <button class @click="cancelSecondaryInput" tabindex="0">
        abbrechen
        <IconClose width="20px" height="20px" />
      </button>
    </transition>
  </div>
</template>

<script>
import IconClose from '@/svg/IconClose.vue';
import { mapStores } from 'pinia'
import { useWidgetStore } from '@/store/widget'

export default {
  name: 'cancelButton',
  components: { IconClose },
  computed: {
    ...mapStores(useWidgetStore),
  },
  methods: {
    cancelSecondaryInput() {
      this.widgetStore.setInputType('textinput')
    },
  },
};
</script>

<style lang="scss" scoped>
.knw_widget_inputbar_cancel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding-right: 8px;
  button {
    display: flex;
    outline: none;
    align-items: center;
    background: transparent;
    border: none;
    color: #a0a0a0;
    font-size: 16px;
    line-height: 19px;
    padding: 10px 10px 10px 20px;
    @include default-focus;
    border-radius: 20px;
    svg {
      margin-left: 10px;
      fill: #aaa;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.delayed_appear-enter {
  opacity: 0;
  height: 0;
  transform: translateY(20px);
}

.delayed_appear-enter-active {
  transition: height $kw-default-transition-speed ease-out,
    opacity $kw-default-transition-speed ease-out,
    transform $kw-default-transition-speed ease-out;
}

.delayed_appear-enter-to {
  opacity: 1;
  height: 50px;
}

.delayed_appear-leave {
  opacity: 1;
}

.delayed_appear-leave-active {
  transition: all 0.2s ease-out;
}

.delayed_appear-leave-to {
  opacity: 0;
}
</style>
