import {
  connect,
  disconnect,
  end,
  setConfig,
  hasConnection,
  sendIntent,
  sendInput,
  addMessageHandler,
  getJWTToken,
  addContext,
  getContext,
  initLivechat,
  startLivechat,
  stopLivechat,
  getTextMessagesHistory,
  livechatCommand,
  livechatMessage,
  agentDataUpdate
} from './bot'


const botService = {
  connect,
  disconnect,
  end,
  setConfig,
  hasConnection,
  sendIntent,
  sendInput,
  addMessageHandler,
  getJWTToken,
  addContext,
  getContext,
  initLivechat,
  startLivechat,
  stopLivechat,
  getTextMessagesHistory,
  livechatCommand,
  livechatMessage,
  agentDataUpdate
}

export default botService