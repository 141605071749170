<template>
  <div class="avatar"
       :style="{'min-width':width, 'min-height': height, width: width, height: height}">
    <img v-if="imageURL"
         :src="imageURL"
         :style="{width: width - 5, height: height - 5}"
         :class="{'shadow': withShadow }"
         alt="Avatar">
    <AvatarSVG v-else :squared="squared" :withShadow="withShadow"/>
  </div>
</template>

<script>
import AvatarSVG from '@/svg/Avatar_default.vue';

export default {
  name: 'Avatar',
  props: ['width', 'height', 'squared', 'image', 'border', 'shadow'],
  components: {
    AvatarSVG,
  },
  computed: {
    imageURL() {
      return this.image;
    },
    withShadow() {
      return this.shadow === 'true';
    },
  },
};
</script>

<style lang="scss" scoped>
  .avatar{
    // border-radius: 50%;
    border-radius: var(--kw-widget-global-borderradius-shape);
    border: 2px solid white;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    svg, img{
      background: #d3d3d3;
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;
      object-position: center center;
      align-self: flex-end;
      transform: scale(1.1);
      transition: transform .15s ease-in-out;
      &:hover {
        transform: scale(1.1);
        transition: transform .15s ease-in-out;
      }
      &:active {
        transform: scale(1.1);
        transition: transform .15s ease-in-out;
      }
      &.shadow{
        filter: $kw-teaser-dropshadow;
      }
      &.squared{
        border-radius: 10%;
        border-radius: 1px solid #d3d3d3;
      }
    }
  }
</style>
