<template>
  <div class="knw_widget_operator">
    <Avatar width="64px" height="64px" :image="getOperatorAvatar" @dblclick="writeVersionMessage" />
    <div class="knw_widget_operator_name">
      <span class="name">{{ getOperatorName }}</span>
      <span class="titel">{{ getOperatorTitel }}</span>
    </div>
    <div v-if="showPoweredBy" class="knw_widget_operator_poweredby">
      <a
        target="_blank"
        rel="nofollow"
        :href="`https://www.moin.ai/?utm_source=kunde&utm_medium=chatbot&utm_campaign=${hostname}`"
      >
        <span>by</span>
        <MoinLogo />
      </a>
    </div>
  </div>
</template>

<script>
import { mapStores, mapState } from 'pinia'
import { useWidgetStore } from '@/store/widget'
import { conversationInfo } from '@/common/messages';
import Avatar from '@/components/Avatar.vue';
import MoinLogo from '@/svg/IconMoinLogo.vue';

export default {
  name: 'WidgetOperator',
  components: {
    Avatar,
    MoinLogo,
  },
  props: {
  },
  data() {
    return {
      hostname: window.parent.location.href.split('/')[2],
    }
  },
  computed: {
    ...mapStores(useWidgetStore),
    ...mapState(useWidgetStore, ['showPoweredBy', 'getOperatorName', 'getOperatorTitel', 'getOperatorAvatar'])
  },
  methods: {
    async writeVersionMessage() {
      this.widgetStore.addMessage(await conversationInfo(`moin.ai Widget Version: ${APP_VERSION}`, Date.now()));
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.knw_widget_operator {
  display: flex;
  height: 48px;
  max-height: 48px;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
  padding: 0 8px 0 4px;
  background: white;
  box-shadow: 0px 10px 20px -10px rgba(50, 50, 50, 0.1);
  .avatar {
    border: 3px solid white;
  }
}

.knw_widget_operator_image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 5px solid white;
  overflow: hidden;
  background: #d3d3d3;
  display: flex;

  svg {
    object-fit: cover;
    width: 100%;
    align-self: flex-end;
    margin: 6px;
    margin-bottom: 0;
  }
}

.knw_widget_operator_name {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  max-width: 210px;

  .name,
  .titel {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $kw-global-textcolor;
  }

  .name {
    @include font_headline;
    display: block;
  }

  .titel {
    @include font_subline;
  }
}

.knw_widget_operator_poweredby {
  margin-left: auto;
  font-style: italic;
  padding-right: 5px;
  font-weight: 400;
  a {
    svg {
      fill: #a6a6a6;
    }
    span {
      color: #a6a6a6;
      margin-right: 4px;
      line-height: 14px;
      font-size: 12px;
      align-self: baseline;
    }

    display: flex;
    align-items: center;
    text-decoration: none;
    color: $kw-global-textcolor-light;
    &:hover {
      color: lighten($kw-global-textcolor-light, 15%);
      span {
        color: #6b6b6b;
      }
      svg {
        fill: #6b6b6b;
      }
    }
  }
}
</style>
