<template>
  <div class="carousel-wrapper" ref="crefwrapper">
    <div class="carousel" :style="carouselStyle" ref="cref">
      <!-- use "old" array refs method because I ran into: https://github.com/vuejs/core/issues/5525 
      TODO fix with future bugfix
      -->
      <div
        class="carousel_item"
        v-for="(item, index) in message.data.items"
        :ref="el => { carouselItems[index] = el }"
        :key="index"
      >
        <component :message="item" :cardIndex="index" :is="comps[item.messageType]"></component>
      </div>
    </div>
    <transition name="button-fade">
      <button v-if="slideIndex > 1" @click="changeSlide(-1)" class="carousel_prev">
        <IconArrow direction="left" />
      </button>
    </transition>
    <transition name="button-fade">
      <button
        v-if="slideIndex < message.data.items.length"
        @click="changeSlide(1)"
        class="carousel_next"
      >
        <IconArrow />
      </button>
    </transition>
  </div>
</template>
<script setup>
import { ref, onMounted, defineProps, onBeforeUnmount, toRefs } from 'vue'
import Media from '@/components/messages/Media.vue';
import Card from '@/components/messages/Card.vue';
import IconArrow from '@/svg/IconArrow.vue';
import { useSwipe } from '@vueuse/core'

const props = defineProps({
  message: Object
})
let resizeObserver = null;

const comps = {
  Media,
  Card
}
const crefwrapper = ref(null)
const cref = ref(null)
const carouselItems = ref([])
const slideIndex = ref(props.message.data.startIndex || 1)
const slideWidth = ref(1)
const carouselStyle = ref({
  transform: `translateX(-10px)`,
})

onMounted(() => {
  slideWidth.value = carouselItems.value[0].scrollWidth;
  try {
    resizeObserver = new ResizeObserver(() => {
      slideWidth.value = carouselItems.value[0].scrollWidth;
      carouselStyle.transform = `translateX(calc(${((slideIndex.value - 1) * - slideWidth.value)}px - ${10 * slideIndex.value}px))`;
    });
    resizeObserver.observe(carouselItems.value[0]);
  } catch (e) {
    // nothing -> should only happen on IE
  }
})

onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.unobserve(carouselItems.value[0]);
  }
})

const changeSlide = (delta) => {
  if (slideIndex.value === 1 && delta < 1) { console.log('supress slide'); return; }
  if (slideIndex.value >= props.message.data.items.length && delta === 1) { console.log('supress slide'); return; }
  slideIndex.value = slideIndex.value + delta;
  carouselStyle.value.transform = `translateX(calc(${((slideIndex.value - 1) * - slideWidth.value)}px - ${10 * slideIndex.value}px))`;
}

const { direction } = useSwipe(
  crefwrapper, {
  passive: true,
  onSwipeEnd(e, direction) {
    // TODO TEST ON IOS 
    console.log('slide')
    if (direction === 'RIGHT') { changeSlide(-1) }
    if (direction === 'LEFT') { changeSlide(1) }
  },
})
</script>
<style lang="scss" scoped>
.carousel-wrapper {
  //margin: 7px 35px 15px 57px;
  margin: 7px 0px 15px 0px;
  /*    overflow: hidden;*/
  position: relative;
  padding-left: 62px;
  margin-left: -5px;
  margin-right: -5px;
  overflow-x: hidden;
}

.carousel {
  display: flex;
  transition: transform 0.5s cubic-bezier(0.3, -0.06, 0.32, 1.28);
  position: relative;
  padding-left: 10px;
  //translate: translateX(-45px);
}

.carousel_item {
  width: 100%;
  min-width: calc(100% - 35px);
  margin: 0 5px;
  border-radius: var(--kw-widget-global-borderradius-inner) !important;
  overflow: hidden;
  display: flex;
  @media (min-width: $kw-widget-breakpoint) {
    /* <<< BREAKPOINT WIDGET <<< */
    min-width: 283px;
  }
  // Force the children to fill all empty space if we want it
  // delegate this decision to @kris und @patrick
  > div {
    min-height: 100%;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  .knw_media {
    padding: 0;
    pointer-events: none;
  }
  img {
    opacity: 0.2;
  }
  .card {
    > img {
      pointer-events: none;
    }
  }
  .card-actions {
    @include kw-btn;
  }
}

.carousel_next,
.carousel_prev {
  position: absolute;
  top: 134px;
  color: white;
  background: var(--kw-messageTypes-carousel-background);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  outline: none;
  @include default-hover-scale;
}

.carousel_prev {
  left: 32px;
  cursor: pointer;
}
.carousel_next {
  cursor: pointer;
  right: 10px;
}

.button-fade-enter-active,
.button-fade-leave-active {
  transition: opacity 0.5s;
}

.button-fade-enter-to,
.button-fade-leave-from {
  opacity: 1;
}

.button-fade-enter, .button-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
