import { createApp } from 'vue'
import App from './App.vue';
import configService from './services/config/'
import { createPinia } from 'pinia'
import { useWidgetStore } from './store/widget';
import botService from '@/services/bot/';
import { getPrimusUrl } from '@/common/utils';
import api from './api'
import { debounce } from 'underscore';
import { getTeaserUrl, signal, SIGNAL_TYPES } from './common/utils';

const initWidget = async () => {

  const mount = document.getElementById('moinai-container')
  const botId = document.body.getAttribute('data-bot');
  const channelId = document.body.getAttribute('data-channel');
  const config = await configService.getBotConfig(botId, channelId);
  const app = createApp(App)
  app.use(createPinia())



  const widgetStore = useWidgetStore()
  /* console.log(widgetStore.getPosition) */
  widgetStore.setConfig({
    ...config,
  })

  botService.setConfig({
    botId: config.botID,
    channelId: config.channelId,
    primusUrl: getPrimusUrl(),
    allowLocalstorage: config.useLocalStorage,
    tokenLifetime: config.tokenLifetime/* || 5000 */, // do we have default lifeTime?
  });
  api.initApi(widgetStore)
  const postMessageHandler = (event) => {
    if (event.data.source === 'host' && event.data.action === 'api') {
      //console.log('API CALL', event.data)
      try {
        api.funcs[event.data.endpoint](event.data.args[0]);
      } catch (error) {
        console.log('api function not implemented')
      }
    } else if (event.data.source === 'host' && event.data.action === 'unmount') {
      console.log('UNMOUNT CALL', event.data)
      unmount()
    }
  }
  window.addEventListener('message', postMessageHandler)


  // WE ADD THE GLU CODE HERE -> IT'S NOT PERFECT BUT IN THE STORE IS EVEN WORSE 
  botService.addMessageHandler((messages) => {
    messages.forEach((msg) => {
      if (msg.type === 'msg') {
        widgetStore.addMessage(msg)
        widgetStore.setIsTyping(false)
      }
      // This are only cmds coming from the bot
      if (msg.type === 'cmd') {
        if (!msg) return;
        switch (msg.cmd) {
          case 'typing': widgetStore.setIsTyping(msg.action_value); break;
          case 'setHistoy': widgetStore.setHistory(msg); break;
          case 'qr': widgetStore.setQuickReplies(msg.qr); break;
          case 'updateHistoy': widgetStore.updateHistory(msg); break;
          case 'setConnectionState': widgetStore.setConnectionState(msg); break;
          case 'addOperator': widgetStore.addOperator(msg); break;
          case 'setOperator': widgetStore.setOperator(msg); break;
          // case 'inputType': commit('SET_INPUTTYPE', payload); break;
          default: console.log('error - command not known', msg);
        }

      }
    });
  });

  app.mount(mount)
  // check inital behaviour from config
  // and restore previous widget state if set
  if (config?.behaviour?.widgetOpen) {
    let lastWidgetState = null;
    if (config.useLocalStorage) {
      const lastWidgetState = window.localStorage.getItem('knowhere_widget_open')
      switch (config.behaviour.widgetOpen) {
        case 'restorePrevious': {
          if (lastWidgetState === 'open') widgetStore.openWidget();
          break;
        }
        case 'open': widgetStore.openWidget(); break;
        case 'close': widgetStore.closeWidget(); break;
        case 'always': widgetStore.openWidget(); break;
        default: console.warn('widget restore mode not configured correctly');
      }
    }
  }
  // If we got a teaser via config -> show teaser
  if (config.teaser) {
    widgetStore.setTeaser(config.teaser)
  }
  // we also fetch a teaser that is based on the route
  // because we can't guarentee any timings with the API 
  // we don't await the fetch and leave it as it is for now 
  // https://github.com/knowhereto/moin-widget/issues/44
  const location = window.parent.location
  const url = getTeaserUrl(botId, channelId, {
    isMobile: widgetStore.isMobile,
    originUrl: `${location.hostname}${location.pathname}`,
  })

  fetch(url)
    .then((res) => res.json())
    .then((data) => {
      if (data.teaser && Object.keys(data.teaser).length !== 0) {
        widgetStore.setTeaser(data.teaser)
      }
    }).catch((error) => {
      // we fail silently -> no critical feature 
      // console.log(error)
      console.info('no remote teaser via route to display')
    })

  // inital set
  widgetStore.setScreensize(window.parent.innerWidth, window.parent.innerHeight)
  // handler on resize
  const screensizeChange = debounce(() => {
    widgetStore.setScreensize(window.parent.innerWidth, window.parent.innerHeight)
  }, 50)
  window.parent.addEventListener('resize', screensizeChange);

  // donw mounting, call host script widget ready
  window.parent.postMessage({ source: 'moin-widget', command: 'init:done' })
  console.log('init:done')
  // timeout to make sure widgetReady function is called first
  setTimeout(() => {
    // no more signal in app mounted, because the customer api handler will no be intialized there
    // instead signal mouned / ready here
    signal(SIGNAL_TYPES.LIFECYCLE, {
      source: "widget",
      timestamp: Date.now(),
      event: "widget:ready",
    })
    signal(SIGNAL_TYPES.LIFECYCLE, {
      source: "widget",
      timestamp: Date.now(),
      event: "mounted",
    })
  })

  const unmount = () => {
    window.parent.removeEventListener('resize', screensizeChange);
    window.removeEventListener('message', postMessageHandler)
    botService.end()
    app.unmount()
    window.parent.postMessage({ source: 'moin-widget', command: 'unmount:done' })
  }
};


initWidget()