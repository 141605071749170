import { createHash } from '../../common/utils';
import {
  textMessage, userMessage, buttons, carousel, image, video,
} from '../../common/messages';
import { isTyping, quickReplies, setHistoy, updateHistoy } from '../../common/commands';

let historyLoaded = false;

const parseMessages = async (rawdata, jwtToken, livechatActive, fromHistory) => {
  const messages = [];

  let source = 'bot';
  let operator = 'default';
  if (!rawdata.messageFromBot && rawdata.recipient !== jwtToken) {
    source = 'user';
  }

  if (livechatActive) {
    console.log("livechat IS active!!", livechatActive);
    operator = 'livechat';
  }

  if (rawdata.typing) {
    messages.push(isTyping(rawdata.typing));
  }

  if (rawdata?.message?.text) {
    if (rawdata.messageFromBot || rawdata.recipient === jwtToken) {
      let mess = { text: rawdata.message.text, timestamp: rawdata.time, source, operator };

      const m = await textMessage(mess);
      messages.push(m);
    } else {
      const m = await userMessage({ text: rawdata.message.text, timestamp: rawdata.time, source });
      messages.push(m);
    }
  }

  if (rawdata.quick_replies && rawdata.quick_replies.length !== 0) {
    const qrs = rawdata.quick_replies.map(item => ({
      text: item.label,
      intent: item.intent,
    }));
    const qr = quickReplies(qrs, rawdata.time);
    messages.push(qr);
  }

  if (rawdata?.message?.attachment && rawdata.message.attachment.type === 'button') {
    if (rawdata.message.attachment.payload.text) {
      const m = await textMessage({
        text: rawdata.message.attachment.payload.text,
        timestamp: rawdata.time,
        source,
        operator,
      });
      messages.push(m);
    }
    const btns = await buttons(rawdata.message.attachment.payload.buttons, rawdata.time, source);
    messages.push(btns);
  }


  if (rawdata?.message?.attachment && rawdata.message.attachment.type === 'cards') {
    const m = await carousel(rawdata.message.attachment.payload.elements, rawdata.time, source);
    messages.push(m);
  }

  if (rawdata?.message?.attachment && rawdata.message.attachment.type === 'image') {
    const m = await image(rawdata.message.attachment.payload, rawdata.time, source);
    messages.push(m);
  }

  if (rawdata?.message?.attachment && rawdata.message.attachment.type === 'video') {
    const m = await video(rawdata.message.attachment.payload, rawdata.time, source);
    messages.push(m);
  }
  return messages;
};

const parseHistory = async (data, jwtToken) => {
  const result = [];
  // console.log('parse history');
  const history = new Map();
  for (const item of data) {
    const results = await parseMessages(item, jwtToken, false, true);
    for (const result of results) {
      // USE THE SENDER TOKEN?
      // THEN WE NEED TO PASS IT THROUGH TOUGH
      const msgType = result.messageType || result.data.messageType;
      const hash = await createHash(`${result.timestamp}${result.source}${msgType}`);
      // if hash already exisits ignore the message
      // propperly a internal state message
      if (history.has(hash)) {
        console.log('hash double');
      } else {
        history.set(hash, result);
      }
    }
  }
  // console.log('history before', history);
  // clean the messages and sort it again (map not keeps the order)
  const cleanedHistory = Array.from(history.values())
    .filter(item => item.type === 'msg')
    .sort((a, b) => a.time - b.time);

  // we need to restore the last quick replies
  // currently I assume that only qr will be attached to the history array
  const commands = Array.from(history.values())
    .filter(item => item.type === 'cmd')
    .sort((a, b) => a.time - b.time);
  if (commands && !historyLoaded) {
    const lastCommand = commands[commands.length - 1];
    const lastMessage = cleanedHistory[cleanedHistory.length - 1];
    // if the last message contains a command (quick replies most likely) we execute this command
    if ((lastCommand && lastMessage) && (lastCommand.time >= lastMessage.timestamp)) {
      result.push(lastCommand);
    }
  }
  if (historyLoaded) {
    result.push(updateHistoy(cleanedHistory));
  } else {
    historyLoaded = true;
    result.push(setHistoy(cleanedHistory));
  }
  result.push(isTyping(false));
  return result;
};

export default {
  parseHistory,
  parseMessages,
};
