import { createHash } from './utils';

export const textMessage = async ({ text, timestamp, source, operator = 'default' }) => {
  return {
    type: 'msg',
    source,
    timestamp,
    key: await createHash(`${timestamp}${source}TextMessage`),
    operator,
    data: {
      messageType: 'TextMessage',
      text,
    },
  };
};

export const userMessage = async ({ text, timestamp, source }) => {
  return {
    type: 'msg',
    source,
    timestamp,
    key: await createHash(`${timestamp}${source}UserMessage`),
    data: {
      messageType: 'UserMessage',
      text,
    },
  };
};

export const buttons = async (items, time, source) => {
  const buttonList = items.map((button) => {
    return {
      type: button.type,
      url: button.type === 'url_postback'
        ? button.payload.url
        : button.url,
      text: button.title,
      payload: button.payload,
    };
  });
  return {
    type: 'msg',
    source,
    time,
    timestamp: time,
    key: await createHash(`${time}${source}ButtonList`),
    data: {
      messageType: 'ButtonList',
      buttons: buttonList,
    },
  };
};

export const carousel = async (items, time, source) => {
  return {
    type: 'msg',
    source,
    timestamp: time,
    key: await createHash(`${time}${source}Carousel`),
    data: {
      messageType: 'Carousel',
      startIndex: 0,
      items: items.map((item) => {
        return {
          messageType: 'Card',
          imageUrl: item.image_url,
          headline: item.title,
          text: item.subtitle,
          actions: item.buttons,
        };
      }),
    },
  };
};


export const image = async (payload, time, source) => {
  return {
    type: 'msg',
    source,
    timestamp: time,
    key: await createHash(`${time}${source}Media`),
    data: {
      messageType: 'Media',
      mediaType: 'image',
      mediaUrl: payload.url,
    },
  };
};


export const video = async ({url}, time, source) => {

  return {
    type: 'msg',
    source,
    timestamp: time,
    key: await createHash(`${time}${source}Media`),
    data: {
      messageType: 'Media',
      mediaType: url.includes('youtube') ? 'youtube' : 'video',
      mediaUrl: url,
    },
  };
};

export const conversationInfo = async (data, time) => {
  return {
    type: 'msg',
    source: 'system',
    timestamp: time,
    key: await createHash(`${time}${'system'}${'CoversationMessage'}`),
    data: {
      messageType: 'CoversationMessage',
      text: data,
    },
  };
};
