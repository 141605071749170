<template>

  <div class="kw-teaser" :class="{ 'mobileteaser': mobileTeaser }" ref="teaser_box">
    <transition name="kw-teaser-background-transition" appear>
      <div class="kw-teaser-background" ref="kw_teaser_background">
        <div class="kw-teaser-content" ref="kw_teaser_content">
          <transition name="kw-teaser-closebutton" appear>
            <div class="kw-teaser-closebutton-wrapper">
              <button class="kw-teaser-closebutton" @click="closeTeaser">
                <IconCloseTeaser />
              </button>
            </div>
          </transition>
          <transition name="kw-teasermsg-transition" appear @after-enter="onAfterEnter">
            <div class="kw-teaser-message" ref="teaserText" v-if="getTeaserText">{{ getTeaserText }}</div>
          </transition>
          <transition name="kw-teaserqa-transition" appear @after-enter="onAfterEnter">
            <div v-if="getQuickActions.length > 0" class="kw-teaser-quickaction-wrapper"
              ref="kw_teaser_quickaction_wrapper">
              <div class="kw-teaser-quickaction" ref="kw_teaser_quickaction">
                <button v-for="(action, index) in getQuickActions" :key="index"
                  @click="teaserAction(action.intent, action.text, action.parameters)">{{ action.text }}</button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import IconCloseTeaser from '@/svg/IconCloseTeaser.vue';

export default {
  name: 'Teaser',
  props: ['modelValue', 'teaser', 'viewportWidth'],
  components: {
    IconCloseTeaser,
  },
  data() {
    return {
      maxTeaserWidth: 480,
      // initial height
      dynamicHeight: 0,
      clicked: false,
    };
  },
  computed: {
    getTeaserText() {
      return this.teaser.message;
    },
    getQuickActions() {
      return this.teaser.qr;
    },
    getWrapperHeight() {
      return `${this.dynamicHeight}px`;
    },
    mobileTeaser() {
      /* if (!window || !window.parent || !window.parent.document
        || !window.parent.document.body || !window.parent.document.body.clientWidth) {
        // just to get sure it cant break here
        return false;
      } */
      return this.viewportWidth <= 768;
    },
  },
  watch: {
    viewportWidth() {
      this.calculateMinimalTeaserBounds()
    },
    mobileTeaser() {
      console.log('watch mobile teaser')
      this.calculateMinimalTeaserBounds()
    },
    getTeaserText() {
      console.log('watch teaser Text')
      this.calculateMinimalTeaserBounds()
    },
    getQuickActions() {
      console.log('watch qrs')
      this.calculateMinimalTeaserBounds()
    }
  },
  methods: {
    onAfterEnter() {
      // there was a small window where the components was already unmounted and this failed
      // because the refs were null but the transition hook fireded anyway (#103)  
      if (this.$refs.kw_teaser_quickaction && this.$refs.teaserText) {
        this.$refs.kw_teaser_quickaction.style.transform = 'translateZ(0)'
        this.$refs.teaserText.style.transform = 'translateZ(0)'
      }
    },
    teaserAction(intent, text, parameters) {
      if (this.clicked) return;
      this.clicked = true;
      setTimeout(() => { this.clicked = false; }, 500);
      this.$emit('quickreply', {
        intent,
        text,
        parameters,
      });
    },
    closeTeaser() {
      this.$emit('close');
    },
    calculateMinimalTeaserBounds() {

      this.$emit('update:modelValue', {
        height: 480,
        width: this.mobileTeaser ? '100%' : `${480}px`,
      });
      // timeout because we need to wait until the frame size is on "max" width
      // to get the correct boundingBoxes for the elements
      setTimeout(() => {
        const height = this.$refs.kw_teaser_background.getBoundingClientRect().height
        const qr = [...this.$refs.kw_teaser_quickaction.children]
        let qrWidth = 0
        if (qr.length === 1) {
          qrWidth = qr[0].getBoundingClientRect().width
        } else {
          // in case we have two or more short qr's the width could be smaller than the 290px
          // we sum up all qr's bounding boxes and can later see we if it's two lines of QR's
          qrWidth = qr.map(item => item.getBoundingClientRect().width)
            .reduce((prev, current) => prev + current, 0)
        }
        // get the longest width of both text and qr's -> the minimal space to optimize for
        // text and qr's in online line but max teaser-width 400px
        const width = Math.max(this.$refs?.teaserText?.getBoundingClientRect().width, qrWidth)
        /* console.log(this.$refs?.teaserText?.getBoundingClientRect().width, qrWidth) */
        this.$emit('update:modelValue', {
          height: height,
          // magic numbers 
          // -> 20px padding left of teaser to not cut off shadows
          // -> 90px margin to the right to not overlap with the button
          // -> 30px give flexbox some space that the render algo does not break 
          width: this.mobileTeaser ? '100%' : `${Math.min(480, width + 140)}px`,
        });
        // It sucks that we have to wait until the iframe is repainted 
        // mutation observer seems not work eighter :/
      }, 100)
    }
  },
  mounted() {
    this.calculateMinimalTeaserBounds()
  }
};
</script>

<style lang="scss">
:root {
  --teaser-content: 100px;
}

.kw-teaser {
  position: absolute;
  width: 100%;
  right: 0px;
  bottom: 0px;

  /* -------------------- */
  /* TEASER MOBILE STYLES */
  /* -------------------- */
  &.mobileteaser {
    width: 100%;

    .kw-teaser-background {
      transition: bottom 0.5s ease-in-out, right 0.5s ease-in-out,
        background-color 0s 0s ease-in-out;
      background: rgba(226, 226, 226, 0.8);
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      padding-top: 10px;
      padding-left: 10px;

      .kw-teaser-content {
        max-width: unset;
        width: 100%;
        align-items: stretch;


        .kw-teaser-message {
          max-width: unset;
          width: auto;
          padding: 16px 10px 14px;
        }
      }

      .kw-teaser-quickaction-wrapper {
        .kw-teaser-quickaction {
          button {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .kw-teaser-background {
    // @include default-dropshadow-light;
    font: $kw-default-font;
    pointer-events: all;
    z-index: 1500;
    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: flex-end;
    min-width: 150px;
    max-width: 100%;
    width: auto;
    box-sizing: border-box;
    // padding-left: $kw-widgetbutton-margin-mobile;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 25px;
    transition: bottom 0.5s ease-in-out, right 0.5s ease-in-out,
      background-color 1.5s 0.5s ease-in-out;
    // background: var($kw-global-background-messagecontainer);
    background: rgba(226, 226, 226, 0);

    &:hover {
      background: rgba(226, 226, 226, 0.8);
      transition: bottom 0.5s ease-in-out, right 0.5s ease-in-out,
        background-color 0.2s 0s ease-in-out;
    }

    border-radius: 10px;
    // @media (min-width: $kw-widget-breakpoint) {
    //   bottom: 0px;
    //   right: 0px;
    // }
    /*   @media (min-width: $kw-teaser-breakpoint) {
    padding-left: 0;
    max-width: 650px;
    width: 650px;
  } */

    button {
      pointer-events: all;
    }

    .avatar-shadow-wrapper {
      overflow: visible;

      .avatar-wrapper {
        background-color: transparent;
        box-sizing: content-box;
        bottom: 0px;
        right: 0px;
        // border-radius: 50%;
        border-radius: var(--kw-widget-global-borderradius-shape);
        border: 3px solid white;
        position: relative;
        bottom: -1px;
        right: -1px;
        min-width: 62px;
        min-height: 62px;
        width: 62px;
        height: 62px;
        cursor: pointer;
        overflow: hidden;
        z-index: 1;

        @media (min-width: $kw-widget-breakpoint) {
          /* <<< MEDIUM <<< */
          margin: 0;
          bottom: 0;
        }

        /* @media (min-width: $kw-teaser-breakpoint) { 
        @include default-hover-scale;
        right: 0px;
      } */
        .avatar {
          background-color: #ffffff;
          position: relative;
          z-index: 0;
          top: -2px;
          left: -2px;

          img {
            width: 100%;
            height: 100%;
          }

          svg {
            border: none;
          }
        }
      }
    }

    .kw-teaser-closebutton-wrapper {
      filter: drop-shadow(0 0 10px $kw-default-shadow-color-light);
      align-self: flex-end;
      position: absolute;
      right: 5px;
      top: 10px;
      padding: 0;
      margin: 0;
      overflow: visible;

      &:hover {
        cursor: pointer;
      }

      /* @media (min-width: $kw-teaser-breakpoint) { 
        left: 30px;
        top: 12px;
      } */
      .kw-teaser-closebutton {
        background: transparent;
        outline: 0;
        border: none;

        @media (min-width: 768px) {
          /* <<< MEDIUM <<< */
          @include default-hover-scale;
          box-shadow: none;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.kw-teaser-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 80px;
  justify-content: flex-end;
  // background: rgba(226, 226, 226, 0.8);
  /* padding-left: $kw-widgetbutton-margin-mobile; */
  /* @media (min-width: $kw-teaser-breakpoint) { 
    padding-bottom: 0px;
    margin-right: 5px;
  } */
}

.kw-teaser-message {
  filter: drop-shadow(0 0 10px $kw-default-shadow-color-light);
  position: relative;
  background: white;
  max-width: 400px;
  //box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
  padding: 12px 10px 10px;
  margin-right: 90px;
  margin-bottom: 0px;
  text-align: left;
  // border-radius: $themeable-border-radius-inside var(--kw-widget-global-borderradius-inner)s 0 var(--kw-widget-global-borderradius-inner);
  border-radius: var(--kw-widget-global-borderradius-inner);
  transform-origin: 100% 100%;
  /* @media (min-width: $kw-teaser-breakpoint) {
    margin-right: 15px;
    padding: 16px 18px 14px;
  } */
}

.kw-teaser-quickaction-wrapper {
  filter: drop-shadow(0 0 10px $kw-default-shadow-color-light);
  overflow: visible;
  transform-origin: 100% 0%;
  margin-right: 90px;
  margin-bottom: 0px;
  padding-top: 15px;

  .kw-teaser-quickaction {
    display: flex;
    justify-content: flex-end;
    flex-flow: row wrap;
    padding: 0;
    overflow: visible;

    /* @media (min-width: $kw-teaser-breakpoint) {
      padding: 0px 15px 15px 20px;
    } */
    button {
      @include qr_pill;
      margin-bottom: 5px;
      border: 1px solid rgba(0, 0, 0, 0);
      border-radius: 22px;
      background: white;
      text-align: left;
      // max-height: 30px;
      margin-left: 10px;
      padding: 7px 10px;
      font-size: 15px;

      &:first-child {
        margin-left: 0px;
      }

      /* @media (min-width: $kw-teaser-breakpoint) { 
        margin-left: 10px;
        padding: 8px 12px;
        font-size: 16px;
      } */
    }
  }
}

/* TEASER TRANSITION */
.kw-teaser.mobileteaser .kw-teaser-background-transition-enter-from {
  background-color: rgba(226, 226, 226, 0) !important;
}

.kw-teaser.mobileteaser .kw-teaser-background-transition-enter-active {
  transition: background-color 0.25s linear !important;
}

.kw-teaser.mobileteaser .kw-teaser-background-transition-enter-to {
  background-color: rgba(226, 226, 226, 0.8) !important;
}

// .kw-teaser-background-transition-leave {
//   background-color: rgba(226,226,226,0.8) !important;
// }
// .kw-teaser-background-transition-leave-active {
//   transition: background-color .25s linear  !important;
// }
// .kw-teaser-background-transition-leave-to {
//   background-color: rgba(226,226,226,0) !important;
// }

@keyframes slideUp {
  0% {
    transform: translateY(var(--teaser-content)) scale(0.5);
    opacity: 0;
  }

  15% {
    transform: translateY(var(--teaser-content)) scale(1);
    opacity: 0.15;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* TEASER MESSAGE TRANSITION */
.kw-teasermsg-transition-enter-from {
  //animation: slideUp 1s 1;
  //opacity: 0;
  //transform: scale(0.5);
}

.kw-teasermsg-transition-enter-active {
  animation-duration: 1s;
  animation-name: slideUp;
  animation-iteration-count: 1;
  /*   transition: all $kw-default-transition-speed-slow
    cubic-bezier(0.1, 0.8, 0.3, 1.2) 0.15s; */
}

.kw-teasermsg-transition-enter-to {

  /* transform: scale(1) ;
  opacity: 1; */
}

/* QUICK ACITON TRANSITION */
.kw-teaserqa-transition-enter-from {
  // height: 0 !important;
  opacity: 0;
}

.kw-teaserqa-transition-enter-active {
  transition: all $kw-default-transition-speed-slow ease-out 0.5s;
}

.kw-teaserqa-transition-enter-to {
  opacity: 1;
}

/* QUICK ACITON BUTTONS TRANSITION */
.kw-teaserqa-buttons-enter-from {
  // height: 0 !important;
  opacity: 0;
}

.kw-teaserqa-buttons-enter-active {
  transition: all $kw-default-transition-speed-slow ease-out 1s;
}

.kw-teaserqa-buttons-enter-to {
  opacity: 1;
}

/* CLOSE TEASER TRANSITION */
.kw-teaser-closebutton-enter-from {
  opacity: 0;
}

.kw-teaser-closebutton-enter-active {
  transition: opacity $kw-default-transition-speed-slow linear 2s;
}

.kw-teaser-closebutton-enter-to {
  opacity: 1;
}

// .kw-teaser-closebutton-leave {
//   opacity: 1;
// }
// .kw-teaser-closebutton-leave-active {
//   transition: opacity $kw-default-transition-speed-fast linear 0s;
// }
// .kw-teaser-closebutton-leave-to {
//   opacity: 0;
// }
</style>
