import bot from '@/services/bot';
import { setOperator, addOperator } from '@/common/commands';
import { textMessage, conversationInfo } from '@/common/messages';


class LivechatincV2 {
  constructor({
    payload,
  }) {
    console.log(`construct livechat object for type ${payload.type}`, payload);

    this.type = payload.type;
    this.payload = payload;
    this.license = payload.license;
    this.group = payload.group;
    this.clientId = payload.clientId;
    this.sdk = {};
    this.status = {};
    this.initMessage = false;
    // this.agentData;
    this.chatId = '';
  }

  async initLiveChat() {
    const {
      success,
      start,
      status,
    } = await new Promise((resolve) => {
      // delete old tokens from livechat

      this.sdk = CustomerSDK.init({
        licenseId: this.license,
        clientId: this.clientId,
        groupId: this.group,
      });
      window.sdk = this.sdk;
      console.log('this.sdk', window.sdk, {
        licenseId: this.license,
        clientId: this.clientId,
        groupId: this.group,
      });

      this.sdk.once('connected', (event) => {
        console.log(event);
        const available = event.availability === 'online';
        if (available) {
          this.registerEventHandlers();

          this.startLivechat({
            text: 'this is irrelevant',
          });

          resolve({
            success: true,
            start: available,
          });
        } else {
          this.sdk.destroy();
          resolve({
            success: false,
            start: available,
          });
        }
      });
    });


    return {
      success,
      start,
    };
  }

  async startLivechat({
    text,
  }) {
    this.sdk.listChats()
      .then(({
        chatsSummary,
        totalChats,
      }) => {
        let chatId; let
          chat;
        if (totalChats > 0) {
          chat = chatsSummary[0];
          chatId = chat.id;
        }

        this.startChat(chatId);
      })
      .catch((error) => {
        console.log(error);
      });


    return {
      success: false,
    };
  }

  async stopLivechat() {
    this.unregisterEventHandlers();

    this.sdk.disconnect();
    return {
      success: false,
    };
  }

  async isAgentAvailable() {
    return {
      available: false,
      error: true,
      message: 'SDK not initialized',
    };
  }

  async startChat(chatId) {
    this.chatId = chatId;

    let initMessage = 'Chatbot Takeover';
    if (window.knowhere.UUID) {
      const chat_history = bot.getTextMessagesHistory(10).join('\r\n');

      const link = `https://hub.moin.ai/#/chats/messaging/${window.knowhere.UUID}`;
      const faq_message = window.knowhere.AskedQuestion;
      const intent_string = window.knowhere.Topics;
      initMessage += `
            
      
            Anfrage: ${faq_message}
      
            Themen: ${intent_string}
            
            Chat: 
            ${chat_history}
          
            Volle Unterhaltung hier: ${link}`;
    }


    if (!chatId) {
      this.sdk.startChat()
        .then((chat) => {
          const welcome_msg_event = chat.chat.thread.events[0];
          setTimeout(() => { this.handleEvents({ event: welcome_msg_event }); }, 2500);
          this.chatId = chat.chat.id;
          this.sendMessage(initMessage);
        })
        .catch((error) => {
          console.log('error on startChat', error);
        });
    } else {
      this.sdk.activateChat({
        chat: {
          id: chatId,
        },
      })
        .then((chat) => {
          const welcome_msg_event = chat.chat.thread.events[0];
          setTimeout(() => { this.handleEvents({ event: welcome_msg_event }); }, 2500);
          this.sendMessage(initMessage);
        })
        .catch((error) => {
          console.log('error on activateChat', error);
        });
    }
  }

  async sendMessage(message) {
    const event = {
      type: 'message',
      text: message,
    };

    this.sdk.sendEvent({
      chatId: this.chatId,
      event,
    })
      .then((event) => {
      })
      .catch((error) => {
        console.log(error);
      });
    return {
      sucess: true,
    };
  }

  async registerEventHandlers() {
    /* this.userToLivechat = PubSub.subscribe(topics.USER_ACTION, (channel, msg) => {
            this.sendMessage(msg.data);
        }); */

    this.sdk.on('incoming_event', this.handleEvents.bind(this));
    this.sdk.on('user_data', this.handleAgendUpdates.bind(this));

    this.sdk.on('new_event', ({
      chat,
      event,
    }) => {
      console.log('new_event', event);
    });


    this.sdk.on('chat_deactivated', ({
      chat,
      event,
    }) => {
      console.log('chat_deactivated', event);

      /* PubSub.publish(topics.INTENT, {
                data: {
                    intent: 'livechat_ended',
                }
            }); */
      bot.intent({
        data: {
          intent: 'livechat_ended',
        },
      });
      this.unregisterEventHandlers();

      this.sdk.destroy();
    });

    return true;
  }

  async unregisterEventHandlers() {
    /* PubSub.unsubscribe(this.userToLivechat); */
    this.sdk.off('incoming_event', this.handleEvents.bind(this));
    this.sdk.off('user_data', this.handleAgendUpdates.bind(this));
  }

  async handleAgendUpdates(update) {
    console.log('agent update', update);

    if (update.type === 'agent') {
      /* widget.addOperator({
        opid: 'livechat',
        name: update.name,
        titel: update.jobTitle,
        avatar_url: update.avatar,
      }); */
      bot.livechatCommand(addOperator({
        opid: 'livechat',
        name: update.name,
        titel: update.jobTitle,
        avatar_url: update.avatar,
      }));

      bot.livechatCommand(setOperator('livechat'));
      /*
      widget.setOperator({
        opid: 'livechat',
      }); */

      if (!this.agentData) {
        this.agentData = update;
        bot.livechatCommand(await conversationInfo('Livechat', Date.now()));
        /* const conversationInfoMsg = await messageService.messages.conversationInfo('Livechat', {
          timestamp: Date.now(),
          source: 'agent',
        });
        
        widget.message(conversationInfoMsg); */
      }
    }
  }


  async handleMessages(message) {
    if (message.type === 'message') {
      const msg = message.text;
      const txtMsg = await textMessage({
        text: msg,
        timestamp: Date.now(),
        source: 'agent',
        operator: 'livechat',
      });
      bot.livechatMessage(txtMsg);

      /* const agentMsg = await messageService.messages.textMessage(msg, {
        timestamp: Date.now(),
        source: 'agent',
        messageType: 'TextMessage',
      });
      widget.livechatMessage(agentMsg);
      bot.livechatMessage(agentMsg); */
    } else {
      console.log('message', message);
    }
  }

  async handleEvents(payload) {
    console.log('event payload', payload);
    const {
      chat,
      event,
    } = payload;

    switch (event.type) {
      case 'message':
        console.log('new message - ', event.text, event);
        this.handleMessages(event);
        break;
      default:
        console.log('different event', event);
        break;
    }
  }
}

export default LivechatincV2;
