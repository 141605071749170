<template>
  <div class="videoplayer">
    <video class="video"
      ref="video"
      preload="metadata"
      controls
      disablepictureinpicture 
      controlslist="nodownload"
      :src="message.imageUrl"
      :type="getMediaType"
      alt=""/>
      <div class="overlay" :class="{show: this.showOverlay}" @click="overlayClick">
        <div class="playpause"></div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Video',
  props: ['message'],
  data() {
    return {
      showOverlay: true,
    };
  },
  computed: {
    getSourceURL() {
      return this.message.imageUrl || this.message.mediaUrl;
    },
    getMediaType() {
      const url = this.message.imageUrl || this.message.mediaUrl;
      const fileEnding = url.split('.').pop();
      switch (fileEnding) {
        case 'webm': return 'video/webm';
        case 'mov': return 'video/quicktime';
        case 'mp4': return 'video/mp4';
        default: return '';
      }
    },
  },
  methods: {
    overlayClick() {
      const videoElement = this.$refs.video;
      if (videoElement.paused) {
        videoElement.play();
        this.showOverlay = false;
      } else {
        videoElement.pause();
        this.showOverlay = true;
      }
    },  
  },
};
</script>

<style lang="scss" scoped>
.videoplayer{
  position: relative;
  line-height: 0;
  border-radius: var(--kw-widget-global-borderradius-inner);
  overflow: hidden;
}
.video {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #33333399;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0,0,0,0);
  z-index: 900;
  transition: background-color .15s;
  pointer-events: none;
  &:hover {
    cursor: pointer;
    .playpause {
      transform: scale(1.15);
      transition: transform .25s;
    }
  }
  &.show{
    transition: background-color .15s;
    background-color: rgba(0,0,0,0.15);
    backdrop-filter: blur(4px);
    pointer-events: all;
    .playpause {
      background-image: url('../../assets/svg/video_play.svg');
    }
  }
  .playpause {
    width: 50%;
    height: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: none;
    z-index: 1000;
    transform: scale(1);
    transition: transform .25s;
  }
}

</style>
