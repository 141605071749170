import objectPath from 'object-path';
import { getConfigUrl } from '@/common/utils';

const v2tov3 = (oldconfig) => {
  const newconfig = {};
  let themeglobalborder = {
    borderradius: {
      outer: '',
      inner: '',
      shape: '',
    },
  };
  if (oldconfig.theme === 'square') {
    themeglobalborder = {
      borderradius: {
        outer: '0px',
        inner: '6px',
        shape: '0px',
      },
    };
  }
  const components = {
    widget: {
      global: {
        ...themeglobalborder,
        position: {
          bottom: '',
          right: '',
          animationEnabled: false,
        }
      },
      header: {
        background: objectPath.get(oldconfig, 'header.background', 'red'),
        textcolor: objectPath.get(oldconfig, 'header.textcolor', 'white'),
        name: objectPath.get(oldconfig, 'header.name')
      },
      operator: {
        default: {
          name: objectPath.get(oldconfig, 'liveChat.defaultOperatorName'),
          titel: objectPath.get(oldconfig, 'liveChat.defaultOperatorTitle'),
          avatar_url: objectPath.get(oldconfig, 'liveChat.defaultOperatorImage')
        }
      },
      conversation: {
        background: objectPath.get(oldconfig, 'msg.container.background'),
      },
      widget_button: {
        background: objectPath.get(oldconfig, 'floatbutton.background'),
        textcolor: '',
      },
      input: {
        placeholder: objectPath.get(oldconfig, 'inputPlaceholder')
      }

    },
    messageTypes: {
      textMessage: {
        background: objectPath.get(oldconfig, 'msg.bot.background'),
        textcolor: objectPath.get(oldconfig, 'msg.bot.textcolor'),
      },
      userMessage: {
        background: objectPath.get(oldconfig, 'msg.user.background'),
        textcolor: objectPath.get(oldconfig, 'msg.user.textcolor'),
      },
      qr: {
        background: objectPath.get(oldconfig, 'msg.qr.background'),
        background_hover: objectPath.get(oldconfig, 'msg.qr.background_hover'),
        textcolor: objectPath.get(oldconfig, 'msg.qr.textcolor'),
        bordercolor: objectPath.get(oldconfig, 'msg.qr.border'),
        bordercolor_hover: objectPath.get(oldconfig, 'msg.qr.border_hover'),
        textcolor_hover: objectPath.get(oldconfig, 'msg.qr.textcolor_hover')
      },
      card: {
        background: objectPath.get(oldconfig, 'msg.card.background'),
        button: {
          background: objectPath.get(oldconfig, 'msg.card.button.background'),
          textcolor: objectPath.get(oldconfig, 'msg.card.button.textcolor'),
          border: objectPath.get(oldconfig, 'msg.card.button.border'),
        }
      },
      carousel: {
        background: objectPath.get(oldconfig, 'msg.card.button_carousel'),
      }
    }
  }

  return {
    ...newconfig,
    version: '4',
    active: oldconfig.active,
    behaviour: {
      widgetOpen: objectPath.get(oldconfig, 'widgetOpen', 'restorePrevious')
    },
    channelId: oldconfig.channelId,
    useLocalStorage: !oldconfig.forceReload,
    poweredBy: oldconfig.poweredBy,
    teaser: oldconfig.teaser,
    components
  }
}

const fetchConfig = async (botID, channelId) => {
  try {
    const response = await fetch(getConfigUrl(botID, channelId), {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
    const data = await response.json();
    if (!data) throw new Error('no config via fetch');
    return data;
  } catch (error) {
    throw new Error(error);
  }
}


export const getBotConfig = async (botID, channelId) => {
  if (!botID) throw new Error('not botID found');

  // issue https://github.com/knowhereto/moin-widget/issues/43
  // the config can be injected with the moin-loader script
  // if so omit the fetch step and use the preinjected config
  // we can assume that the preinjected config ist always v3 or above 
  let data;
  if (window.parent.moin.config) {
    data = window.parent.moin.config
  } else {
    data = await fetchConfig(botID, channelId)
  }

  const version = parseInt(data.version, 10)
  if (!version || version < 2) throw Error('version property not found or version is too old')
  // incase we get a version 2, we transform it 
  // we should aim to migrate away from this and avoid too old versions 
  const transformedConfig = version === 2 ? v2tov3(data) : data
  // Monkeypatch config 
  const config = {
    botID,
    ...transformedConfig,
    // TODO why is this here -> i think we can remove it, test it and remove it
    useLocalStorage: data.useLocalStorage
  };
  // legacy field which is now covered by useLocalStorage 
  if (data.forceReload) {
    config.useLocalStorage = false;
  }
  return config
}

export default {
  getBotConfig
}