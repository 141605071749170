<template>
  <div class="youTubeEmbedd">
    <iframe width="100%"
      :src="getURL"
      frameborder="0" 
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: 'YouTubeEmbedd',
  props: ['message'],
  computed: {
    getURL() {
      return this.message.imageUrl || this.message.mediaUrl;
    },
  },
};
</script>

<style lang="scss">
.youTubeEmbedd {
  position: relative;
  display: flex;
  line-height: 0;
  border-radius: var(--kw-widget-global-borderradius-inner);
  overflow: hidden;
  width: 100%;

  iframe{
    height: 100%;
  }
}

</style>