import bot from '@/services/bot';
import {
  setOperator,
  addOperator,
  isTyping,
} from '@/common/commands';
import {
  textMessage,
  conversationInfo
} from '@/common/messages';


class LivechatMock {
  constructor({ payload }) { }

  initLiveChat() {
    console.log('[LivechatMock] init');
    return { success: true };
  }

  async startLivechat({ text }) {
    console.log('[LivechatMock] start livechat');
    const agentData = {
      opid: 'livechat',
      display_name: 'mock name',
      title: 'mock title',
      avatar_url: 'https://assets.website-files.com/5e71ed18578a651dd5c5f259/61150bf8dd8c32bd7fbe252e_cto_florian.jpg'
    };
    bot.livechatCommand(addOperator(agentData));
    bot.livechatCommand(setOperator('livechat'));

    setTimeout(async () => {
      const txtMsg = await textMessage({
        text: 'livechat mock. Input "/end" to call stopLivechat',
        timestamp: Date.now(),
        source: 'agent',
        operator: 'livechat',
      });
      bot.livechatMessage(txtMsg);

    }, 1000)
    return {
      success: true,
    };
  }

  stopLivechat() {
    console.log('[LivechatMock] stopLivechat');
    return {
      success: false,
    };
  }

  async sendMessage(messageText) {
    console.log('[LivechatMock] send message')
    if (messageText === '/end') {
      bot.stopLivechat({ text: 'end livechat from mock' })
      return true
    }
    const typing = isTyping(true)
    bot.livechatCommand(typing)
    setTimeout(async () => {
      const txtMsg = await textMessage({
        text: `mirror: ${messageText}`,
        timestamp: Date.now(),
        source: 'agent',
        operator: 'livechat',
      });
      bot.livechatMessage(txtMsg);
    }, 1500)
    return true;
  }

}

export default LivechatMock;
