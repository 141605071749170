<template>
  <div class="knw_widget_header">
    <!-- temporarily removing menubutton -->
    <!-- <div class="knw_widget_header_menu">
      <IconBurger />
    </div>-->
    <span class="knw_widget_header_body">{{ widgetName }}</span>
    <div class="knw_widget_header_action" v-if="showCloseButton" @click="close">
      <div>
        <IconClose />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import IconClose from "@/svg/IconClose.vue";
import { useWidgetStore } from '@/store/widget'

const widgetStore = useWidgetStore()

const showCloseButton = computed(() => {
  return !(widgetStore.getWidgetBehaviour.widgetOpen === "always");
})

const widgetName = computed(() => {
  return widgetStore.getWidgetHeaderName;
})

const close = () => {
  // store.commit("SET_OPENSTATE", "close");
  widgetStore.closeWidget()
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.knw_widget_header {
  min-height: 70px;
  background-color: var(--kw-widget-header-background);
  color: var(--kw-widget-header-textcolor);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: background-color 0.2s ease-out;
  svg {
    fill: var(--kw-widget-header-textcolor);
    stroke: var(--kw-widget-header-textcolor);
  }

  .knw_widget_header_menu {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    // temporarily removing menubutton
    display: none;
  }

  .knw_widget_header_body {
    // I have NO Idea why it 180px... in my logic it should be -100px... wtf?
    // max-width: calc(100% - 200px);
    // it somehow needs to be px sized... I'm puzzeld...
    max-width: 350px;
    //flex: 1;
    // text-overflow: ellipsis;
    overflow: hidden;
    /*      white-space: nowrap;*/
    padding: 15px 15px 25px 15px;
    @include font_brandheader;
    align-self: center;
    // temporary restyling of header as long as menu is not used
    text-align: left;
  }

  .knw_widget_header_action {
    width: 50px;
    height: 60px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include default-hover-scale;
    svg {
      display: block;
      margin: 0 auto;
      width: 25px;
      height: 25px;
    }
  }
}
</style>
