export const getBotPath = () => {
  const path = import.meta.env.VITE_APP_BOT_PATH
  if (!path) throw new Error(`env variable is missing: VITE_APP_BOT_PATH`)
  return path
}

export const getApiPath = () => {
  const path = import.meta.env.VITE_APP_API_PATH
  if (!path) throw new Error(`env variable is missing: VITE_APP_API_PATH`)
  return path
}
