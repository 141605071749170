<template>
  <div class="knw-button-list-wrapper">
    <div class="knw-button-list" v-for="(button, index) in message.data.buttons" :key="index">
      <a
        v-if="button.type === 'web_url'"
        class="btn"
        @click="handleButtonClick(button);"
        target="_blank"
        rel="nofollow"
        :href="button.url"
        :title="button.text"
      >
        <span>
          {{ button.text }}
          <IconExternalLink class="btn_link" width="10px" height="10px" />
        </span>
      </a>

      <a
        class="btn"
        target="_blank"
        rel="nofollow"
        v-if="button.type === 'url_postback'"
        :href="button.url"
        :title="button.text"
        @click="handleButtonClick(button)"
      >
        <span>
          {{ button.text }}
          <IconExternalLink class="btn_link" width="10px" height="10px" />
        </span>
      </a>

      <a
        v-if="button.type === 'mail'"
        @click="handleButtonClick(button);"
        class="btn"
        :title="button.text"
        :href="getMailTo(button.email)"
      >
        <span>{{ button.text }}</span>
      </a>
      <a
        class="btn"
        v-if="button.type === 'tel'"
        :href="getPhoneNumber(button.number)"
        @click="handleButtonClick(button)"
      >
        <span>{{ button.text }}</span>
      </a>
      <button class="btn" v-if="button.type === 'postback'" @click="handleButtonClick(button)">
        <span>{{ button.text }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import IconExternalLink from '@/svg/IconExternalLink.vue';
import { mapStores } from 'pinia'
import { useWidgetStore } from '@/store/widget'
import { signal, SIGNAL_TYPES } from '../../common/utils';

export default {
  name: 'ButtonList',
  props: ['message'],
  components: { IconExternalLink },
  computed: {
    ...mapStores(useWidgetStore),
    getButtons() {
      return this.message.buttons;
    },
  },
  methods: {
    handleButtonClick(context) {
      const target = context.url || context.number || context.email;
      const data = {
        source: 'buttonList',
        intent: context?.payload?.name,
        text: context?.text,
        target,
        type: context?.type,
        parameters: context?.payload?.parameters,
      };
      // signal always
      signal(SIGNAL_TYPES.CLICK, data)
      // only send Intent on buttons -> postback and url_postback
      if (context.type === 'postback' || context.type === 'url_postback') {
        this.widgetStore.intent(data)
      }
    },
    getPhoneNumber(number) {
      return `tel:${number}`;
    },
    getMailTo(adress) {
      return `mailto:${adress}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.knw-button-list-wrapper {
  margin: 10px 0;
  .knw-button-list {
    margin: 4px 0;
    padding-right: 35px;
    padding-left: 57px;
    .btn {
      font-weight: 400;
      border: 1px solid $kw-default-button-border-color;
      &:hover {
        border: 1px solid $kw-default-button-border-color-hover;
      }
      > span {
        position: relative;
        display: block;
        padding: 7px 20px;
        width: 90%;
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
@include kw_btn;

.btn {
  background: white;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
