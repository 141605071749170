import messageparser from './messageparser';

import {
  setConnectionState,
  setOperator,
} from '../../common/commands';
import {
  conversationInfo,
} from '../../common/messages';
import Zendesk from './services/livechat/zendesk';
import LivechatincV2 from './services/livechat/livechatincv2';
import Server2Server from './services/livechat/server2server';
import LivechatMock from './services/livechat/livechatMock';

const defaultContext = window.parent.location.href.replace("'", '');
let primus = null;
let config = null;
let jwtToken = null;
let livechat = null;
let isConnected = false;
let localHistoryCopy = [];
const context = [];
const messageHandler = [];

// make sure we use this little helper respect the config if we allowed to use the locastorage
export const setJWTToken = (token) => {
  jwtToken = token;
  if (config.allowLocalstorage) {
    window.localStorage.setItem(`knowhere_jwt_${config.botId}_${config.channelId}`, JSON.stringify({
      token,
      timestamp: Date.now(),
    }));
  }
};

export const hasConnection = () => {
  return isConnected;
};

export const getJWTToken = () => {
  return jwtToken;
};

export const addContext = (ctx) => {
  for (const key in ctx) {
    const ctxString = `${key}('${ctx[key]}')`;
    context.push(ctxString);
  }
};

export const getContext = () => {
  return context;
};

/**
 * Get the last messages from the widget that are of Type TextMessage or UserMessage and
 * reduces it to an array of text Strings.
 * @param {*} amount default 20, the amount of messages that matches the criteria
 * @param {*} filter default: ['TextMessage', 'UserMessage']. With passing only of the two you can reduce it further
 */
export const getTextMessagesHistory = (amount = 20, filter = ['TextMessage', 'UserMessage']) => {
  return localHistoryCopy
    .filter((item) => {
      return filter.includes(item.data.messageType);
    }).map((item) => {
      return (item.data.messageType === 'UserMessage') ? `USER: ${item.data.text}` :
        `Bot: ${item.data.text}`;
    }).slice(parseInt(amount, 10) * -1);
};

export const addMessageHandler = (fn) => {
  messageHandler.push(fn);
};

/**
 * @param {*} messages one or multiple messages or commands that will be send to the widget
 * @returns {Array} an array of commands or messages.
 */
const reportNewMessages = (messages) => {
  messageHandler.forEach((callback) => {
    // if we only be called with one command (e.g. connection state update) we wrap it
    // into an array that the handler can safely iterrate
    if (Array.isArray(messages)) {
      callback(messages);
    } else {
      callback([messages]);
    }
  });
};

const handleBotcommands = (data) => {
  console.log('BOT_COMMAND', data);
  const botcommand = data.command;
  switch (botcommand) {
    case 'init_livechat_takeover':
      initLivechat({
        text: 'init livechat from bot',
        payload: data.payload,
      });
      break;
    case 'start_livechat_takeover':
      startLivechat({
        text: 'Livechat',
      });
      break;
    case 'stop_livechat_takeover':
      stopLivechat({
        text: 'Chatbot',
      });
      break;
    case 'send_context':
      // TODO: move this
      const ctx = data.payload;

      // put all ctx into window element
      window.knowhere = {};
      window.knowhere.context = {};
      ctx.forEach((context) => {
        window.knowhere.context[context.name] = context.parameters || true;
      });

      const asked = ctx.find(element => (element.name == 'faq_message'));
      window.knowhere.AskedQuestion = asked ? asked.parameters : '';

      const uniqueUserId = ctx.find(element => (element.name == 'uniqueUserId'));
      window.knowhere.UUID = uniqueUserId ? uniqueUserId.parameters : '';

      const inte = ctx.find(element => (element.name == 'intents'));

      const intents = inte ? inte.parameters : [];
      let resultString = '';
      if (Array.isArray(intents)) {
        if (intents) {
          intents.forEach((intent) => {
            resultString += `${intent} `;
          });
        }
      } else {
        resultString = intents;
      }

      window.knowhere.Topics = resultString;

      const name = ctx.find(element => (element.name == 'user_name'));
      window.knowhere.user_name = name ? name.parameters : '';

      const details = ctx.find(element => (element.name == 'takeover_details'));
      window.knowhere.takeover_details = details ? details.parameters : '';
      break;
    case 'agent_data':
      console.log("agent_data", data);
      agentDataUpdate(data.payload);
      break;
    default:
      console.log('no command operation found');
      break;
  }
};

const outgoingURLHandler = (url) => {
  url.query = 'referrer=local';
  url.query += `&bot=${config.channelId || ''}`;
};

const dataHandler = async (data) => {
  // This seems to be the first open of the widget, therefore save the token.
  if (data.recipient && !getJWTToken()) {
    setJWTToken(data.recipient);
  }

  if (data.recipient && data.api) {
    // backend command
    // handle
    console.log('backend command');
    handleBotcommands(data);
  }

  if (Array.isArray(data)) {
    // handle history update
    const messages = await messageparser.parseHistory(data, getJWTToken());
    // we omit applying the history when the livechat is active becauses we 
    // have a strange behaviour we can't relly explain at the backend 
    if (!isLivechatActive()) {
      reportNewMessages(messages);
    }
  } else {
    // handle normal bot message
    const messages = await messageparser.parseMessages(data, getJWTToken(), isLivechatActive());
    reportNewMessages(messages);
    /* console.log('message from bot', data, messages); */
  }
};

const connectionStatusHandler = (error, status) => {
  let state = status;
  let message = '';
  let timeout = 0;

  switch (status) {
    case 'offline':
      state = 'offline';
      message = 'i am offline';
      break;
    case 'reconnect failed':
      state = 'offline';
      message = 'reconnect failed';
      break;
    case 'reconnect':
      state = 'warning';
      message = 'reconnecting';
      break;
    case 'reconnected':
      state = 'online';
      message = 'i am back online';
      timeout = 2000;
      break;
    case 'error':
      state = 'offline';
      message = 'error connecting';
      break;
    default:
      console.log('this should never happen');
  }

  reportNewMessages(setConnectionState({
    state,
    message,
    timeout
  }));

  if (error) {
    console.error('[ER] primus error', error);
    return false;
  }
};

const connected = (initialMessage) => {
  const msg = {
    time: (new Date()).getTime(),
    recipient: config.channelId,
    options: {},
    requestHistory: true,
    context: [`widget_url('${defaultContext}')`].concat(getContext()),
  };

  // if there is no token, we need to start a new conversation
  if (!getJWTToken()) {
    msg.start = true;
  } else {
    msg.sender = getJWTToken();
  }

  // if we have an initial intent, we add it to the message
  if (initialMessage) {
    msg.message = initialMessage;
  }
  primus.write(msg);
};

/*
  removed the automatic disconnect and reconnect on setting a new config
  we don't run into that case.
*/
export const setConfig = ({
  botId,
  channelId,
  primusUrl,
  allowLocalstorage,
  tokenLifetime,
}) => {
  if (config) throw new Error('cnfig already set');
  config = {
    botId,
    channelId,
    primusUrl,
    allowLocalstorage,
    tokenLifetime,
  };
  if (config.allowLocalstorage) {
    let jwt;
    try {
      jwt = JSON.parse(window.localStorage.getItem(`knowhere_jwt_${config.botId}_${config.channelId}`));
    } catch (error) {
      // if we have an old - non json - token, pruge it
      jwt = null;
    }
    /* console.log(tokenLifetime, Date.now() - jwt.timestamp, Date.now() - jwt.timestamp > tokenLifetime); */
    // purge token if lifetime is expired, don't do anything if no lifetime is set
    if (tokenLifetime && jwt && Date.now() - jwt.timestamp > tokenLifetime) {
      window.localStorage.removeItem(`knowhere_jwt_${config.botId}_${config.channelId}`);
      // also purge the open state
      window.localStorage.removeItem('knowhere_widget_open');
      jwt = null;
    }
    if (jwt) {
      setJWTToken(jwt.token);
    }
  }
};

export const connect = async (initialMessage) => {
  return new Promise((resolve, reject) => {
    if (!config) reject(new Error('No configuration was found'));
    if (!Primus) reject(new Error('Primus not loaded'));
    if (isConnected) reject(new Error('already connected'));

    try {
      primus = new Primus(config.primusUrl, {
        reconnect: {
          max: Infinity, // Number: The max delay before we try to reconnect.
          min: 500, // Number: The minimum delay before we try reconnect.
          retries: 100, // Number: How many times we should try to reconnect.
        },
      });

      primus.on('outgoing::url', outgoingURLHandler);
      // we need this handler here to resolve the promise that the connection 
      // was successful and is open and ready to go
      primus.on('open', () => {
        isConnected = true;
        connected(initialMessage);
        resolve(true);
      });
      primus.on('data', dataHandler);

      primus.on('offline', () => {
        connectionStatusHandler(null, 'offline');
      });
      primus.on('reconnect failed', () => {
        connectionStatusHandler(null, 'reconnect failed');
      });
      primus.on('reconnect', () => {
        connectionStatusHandler(null, 'reconnect');
      });
      primus.on('reconnected', () => {
        connectionStatusHandler(null, 'reconnected');
      });
      primus.on('error', (error) => {
        connectionStatusHandler(error, 'error');
      });
    } catch (error) {
      console.log('[ER] error initializing primus', error);
      reject(error);
    }
  });
};

export const sendIntent = async ({
  timestamp,
  text,
  intent,
  parameters = { confidence: 1.0 },
  api = !text,
}) => {
  console.log("sendIntent", {
    timestamp,
    text,
    intent,
    parameters,
    api,
  });
  /* console.log(timestamp, intent, text, parameters); */
  if (!isConnected) {
    /* console.log('SEND INTENT', timestamp, text, intent); */
    // if we are not connected yet, we assume this is an click on a teaser quick reply
    // we use the connected handler to send the intial message and make sure all fields are
    // propperly set like requestHistory and stuff.
    await connect({
      text,
      intent: {
        name: intent,
        parameters,
      },
    });
    return;
  }

  const msg = {
    time: timestamp || Date.now(),
    recipient: config.channelId,
    sender: getJWTToken(),
    message: {
      text,
      intent: {
        name: intent,
        parameters,
      },
    },
    context: [`widget_url('${defaultContext}')`].concat(getContext()),
  };
  // edgecase 
  // TODO: check if that works propperly
  // not sure
  if (api) {
    msg.api = true;
    // for whatever reason we need sender and recipient here  ¯\_(ツ)_/¯
    msg.message.recipient = config.channelId;
    msg.message.sender = getJWTToken();
    // make sure we don't send any text
    delete msg.message.text;
  }
  console.log(msg);
  primus.write(msg);
};

export const sendInput = async (msg) => {
  console.log("event", msg)
  // hook in livechat here if active
  if (isLivechatActive()) {
    // SEND TO LIVECHAT
    livechat.sendMessage(msg.data.text);
  }
  // send also to livechat
  /* console.log('sendInput to backend', msg.timestamp, msg); */
  if (!primus || !isConnected) {
    console.error('no primus || no connection');
    return;
  }
  const message = {
    time: msg.timestamp,
    recipient: config.channelId,
    sender: getJWTToken(),
    message: {
      text: msg.data.text,
      context: [`widget_url('${defaultContext}')`].concat(getContext()),
    },
  };
  console.log("sending message", message, msg);
  primus.write(message);
};

export const disconnect = () => {
  primus.destroy({
    timeout: 10000,
  });
  primus = null;
};

export const end = () => {
  primus.end();
  primus = null;
};


export const livechatCommand = (cmd) => {
  reportNewMessages(cmd)
}
// LIVECHAT INTEGRATION BELOW HERE
export const livechatMessage = (msg) => {
  reportNewMessages(msg)
  //console.log('livechatMessage', msg);
  const message = {
    time: msg.timestamp,
    messageFromBot: true,
    recipient: getJWTToken(),
    sender: config.channelId,
    message: {
      type: msg.data.messageType,
      text: msg.data.text,
      messageFromBot: true,
      context: [`widget_url('${defaultContext}')`].concat(getContext()),
    },
  };
  if (primus) {
    //console.log('send LIVECHAT back to Bot', message);
    primus.write(message);
  }
};

const isLivechatAvailable = async () => {
  if (!livechat) return false;
  const availability = await livechat.isAgentAvailable();
  if (availability.error) throw new Error(availability.message);
  return availability.available;
};

let shouldInitLiveChat = true;

const loadScript = (scriptSrc) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = scriptSrc;
    script.onload = () => {
      resolve();
    };
    document.head.appendChild(script);
  });
};

export const initLivechat = async ({
  text,
  payload
}) => {
  if (livechat) throw new Error('There is already a livechat session open');
  let isServer2Server = false;
  let script;

  switch (payload.type) {
    case 'zendesk':

      await loadScript('https://widget.moin.ai/zendesk-web-sdk.js');
      livechat = new Zendesk({ payload });
      console.log('after zendesk creation', livechat);
      break;
    case 'livechatincv2':
      await loadScript('https://widget.moin.ai/livechat-customer-sdk.js');
      livechat = new LivechatincV2({ payload });
    case 'mock_livechat':
      console.log('livechat mock')
      // we also have to pretend to be server2server here 
      // otherwise we run into isLivechatAvailable issues 
      isServer2Server = true;
      livechat = new LivechatMock({ payload });
      break;
    default:
      console.log('in default case, trying to instantitate server2server')
      isServer2Server = true;
      livechat = new Server2Server({ payload });
  }

  const {
    success,
    start
  } = await livechat.initLiveChat(shouldInitLiveChat);
  shouldInitLiveChat = false;
  let available;

  // in case of e.g. zendesk check availability as its not indicated in init message
  // in this case the rules can then consider to start the livechat
  // if start is set the livechat will be initated automatically

  if (!isServer2Server) {
    console.log('!isServer2Server', success, start);
    if (success && !start) {
      available = await isLivechatAvailable();
    }
    console.log('!isServer2Server 2', success, start, available);
    if ((success && available) || (start && success)) {
      console.log('sending available');
      sendIntent({
        intent: 'livechat_available',
      });
    } else {
      console.log('sending not available');
      livechat = null;
      sendIntent({
        intent: 'livechat_not_available',
      });
    }
  }
};

export const startLivechat = async ({
  text
}) => {
  const status = await livechat.startLivechat({
    text
  });
  if (status.success) {
    const convinfo = await conversationInfo(text, Date.now());
    livechatMessage(convinfo);
  }
};

export const stopLivechat = async ({
  text
}) => {
  console.log('in enginecore stopLivechat')
  if (!livechat) return;
  const convinfo = await conversationInfo(text, Date.now());
  livechatMessage(convinfo);


  livechatCommand(setOperator('default'));
  // report chnages to widget & backend


  sendIntent({
    intent: 'livechat_ended'
  });

  await livechat.stopLivechat(true);
  livechat = null;
};

const isLivechatActive = () => {
  return livechat ? true : false;
};

export const agentDataUpdate = async (data) => {
  const status = await livechat.setOperator(data);
};