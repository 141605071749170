<template>
<!-- eslint-disable max-len-->
<svg width="67px" height="64px" viewBox="0 0 67 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 62 (91390) - https://sketch.com -->
    <defs>
        <path d="M33,65 C66,65 65.6,65 65.8,33 C66,1 66,1 33,1 C-1.84852134e-14,1 -6.8278716e-15,1 0.2,33 C0.4,65 9.58261248e-13,65 33,65 Z" id="path-1"></path>
        <path d="M3.15312065,1.89141074 C8.0170585,1.20049297 13.1718531,0.855034086 18.6175045,0.855034086 C24.1854243,0.855034086 29.755863,1.21617994 35.3288205,1.93847164 C37.0499868,2.16155495 38.2795856,3.71599351 38.100413,5.44228339 L36.5881139,20.0129582 C36.4188677,21.6436091 35.0446223,22.8826029 33.4052119,22.8826029 L5.12088979,22.8826029 C3.48147937,22.8826029 2.10723398,21.6436091 1.93798775,20.0129582 L0.420259409,5.38997299 C0.242640416,3.67876133 1.44981428,2.13336379 3.15312065,1.89141074 Z" id="path-3"></path>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-411.000000, -288.000000)">
            <g id="Avatar-Default" transform="translate(411.500000, 287.000000)">
                <circle id="Round-Icon" cx="33" cy="33" r="33"></circle>
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <use id="Mask" fill="#FFFFFF" xlink:href="#path-1"></use>
                <g id="BG-Color" opacity="0.495744978" mask="url(#mask-2)" fill="#929292">
                    <g transform="translate(0.200000, 1.000000)" id="Color">
                        <rect x="0" y="0" width="65.6" height="64"></rect>
                    </g>
                </g>
                <g id="Avatar2" mask="url(#mask-2)">
                    <g transform="translate(7.212881, 11.300000)">
                        <g id="Group" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(14.076493, 0.699629)">
                            <path d="M11.7311295,0.459096928 C12.8109502,0.459096928 13.6863178,1.33446453 13.6863178,2.41428518 L13.6863178,3.88049523 L13.6863178,3.88049523 L9.77594127,3.88049523 L9.77594127,2.41428518 C9.77594127,1.33446453 10.6513089,0.459096928 11.7311295,0.459096928 Z" id="Button3" fill="#F89D1C"></path>
                            <path d="M6.84315889,0 C7.92297954,-1.98359836e-16 8.79834714,0.875367598 8.79834714,1.95518825 L8.79834714,4.33959216 L8.79834714,4.33959216 L4.88797063,4.33959216 L4.88797063,1.95518825 C4.88797063,0.875367598 5.76333823,1.98359836e-16 6.84315889,0 Z" id="Button2" fill="#4380C1"></path>
                            <path d="M1.95518825,0.459096928 C3.03500891,0.459096928 3.91037651,1.33446453 3.91037651,2.41428518 L3.91037651,3.88049523 L3.91037651,3.88049523 L2.56462572e-13,3.88049523 L2.56462572e-13,2.41428518 C2.56108288e-13,1.33446453 0.875367598,0.459096928 1.95518825,0.459096928 Z" id="Button1" fill="#A11D47"></path>
                        </g>
                        <rect id="Rectangle" stroke="none" fill="#376EB4" fill-rule="evenodd" x="22.1227149" y="30.2513265" width="7.31253634" height="4.9904661"></rect>
                        <rect id="Face" stroke="#FFFFFF" stroke-width="1.92" fill="#2B4754" fill-rule="evenodd" x="1.83322034" y="4.63425847" width="47.8915254" height="24.7070657" rx="12.3535328"></rect>
                        <path d="M42.8783385,36.5242639 L47.5614484,36.5242639 C48.1979129,36.5242851 48.7738516,36.9015221 49.0281587,37.4849729 C50.1325501,40.018752 50.6847458,42.203987 50.6847458,44.040678 C50.6847458,45.8402963 50.1546166,47.6094839 49.0943584,49.3482408 C48.803889,49.824609 48.2862407,50.1152542 47.7282987,50.1152542 L42.8783385,50.1152542 C41.9946829,50.1152542 41.2783385,49.3989098 41.2783385,48.5152542 L41.2783385,38.1242639 C41.2783385,37.2406083 41.9946829,36.5242639 42.8783385,36.5242639 Z" id="Rectangle-Copy" stroke="none" fill="#376EB4" fill-rule="evenodd"></path>
                        <path d="M3.93442782,36.5242639 L8.67962764,36.5242639 C9.56328324,36.5242639 10.2796276,37.2406083 10.2796276,38.1242639 L10.2796276,48.5152542 C10.2796276,49.3989098 9.56328324,50.1152542 8.67962764,50.1152542 L3.72871467,50.1152542 C3.21316558,50.115258 2.7292031,49.8668383 2.4286746,49.4479428 C1.39170509,48.0025494 0.873220339,46.2001278 0.873220339,44.040678 C0.873220339,41.8335639 1.41484611,39.6264499 2.49809765,37.4193358 C2.76697362,36.8714521 3.32412388,36.5242639 3.93442782,36.5242639 Z" id="Rectangle-Copy-2" stroke="none" fill="#376EB4" fill-rule="evenodd"></path>
                        <g id="Body" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(6.515932, 31.891525)">
                            <mask id="mask-4" fill="white">
                                <use xlink:href="#path-3"></use>
                            </mask>
                            <use fill="#9CB8DA" xlink:href="#path-3"></use>
                            <g id="Body-Color" opacity="0.510532924" mask="url(#mask-4)" fill="#376EB4">
                                <g transform="translate(-8.755932, -6.074576)" id="Color">
                                    <rect x="0" y="0" width="56.6907054" height="30.1659958"></rect>
                                </g>
                            </g>
                        </g>
                        <ellipse id="R-Eye" stroke="#FFFFFF" stroke-width="1.6" fill="none" cx="14.0764926" cy="17.1575079" rx="6.53482079" ry="6.206841"></ellipse>
                        <ellipse id="R-Pupil" stroke="none" fill="#FFFFFF" fill-rule="evenodd" cx="14.9535178" cy="17.1575079" rx="1.94576271" ry="1.89830508"></ellipse>
                        <ellipse id="L-Eye" stroke="#FFFFFF" stroke-width="1.6" fill="none" cx="37.2840332" cy="16.8180747" rx="6.53482079" ry="6.206841"></ellipse>
                        <ellipse id="L-Pupil" stroke="none" fill="#FFFFFF" fill-rule="evenodd" cx="38.1610584" cy="16.8180747" rx="1.94576271" ry="1.89830508"></ellipse>
                        <g id="knowhere-logo-rebuilt-copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(17.995932, 36.524264)">
                            <path d="M6.584,11.134 L9.876,16.273 L7.7534587,17.4691179 L5.051,15.947 L5.051,12.989 L6.584,11.134 Z M7.7534587,0 L15.5069174,4.36727948 L15.5069174,13.1018384 L11.967,15.095 L8.188,9.194 L12.0850544,4.48033888 L9.20272451,4.48033888 L5.051,9.487 L5.051,1.522 L7.7534587,0 Z M2.731,2.829 L2.731,14.64 L-6.61450583e-13,13.1018384 L-6.66557609e-13,4.36727948 L2.731,2.829 Z" id="Combined-Shape" fill="#356175"></path>
                        </g>
                        <path d="M23.4440678,24.3602042 C24.4818079,24.6757972 25.260113,24.8335936 25.7789831,24.8335936 C26.2978531,24.8335936 27.0761582,24.6757972 28.1138983,24.3602042" id="Line-2" stroke="#FFFFFF" stroke-width="1.6" fill="none" stroke-linecap="round"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>
<script>
export default {
  name: 'Avatar_SVG',
  props: ['squared', 'withShadow'],
};
</script>
