<template>
  <svg style="width:24px;height:24px" :style="{transform: getDirection}" viewBox="0 0 24 24">
      <path fill="white"
        d="M4,10V14H13L9.5,17.5L11.92,19.92L19.84,12L11.92,4.08L9.5,6.5L13,10H4Z" />
  </svg>
</template>
<script>
export default {
  props: ['direction'],
  computed: {
    getDirection() {
      switch (this.direction) {
        case 'left': return 'rotate(180deg)';
        case 'right': return 'rotate(90deg)';
        default: return '';
      }
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
