import botService from '@/services/bot';
import { defineStore } from 'pinia'
import objectPath from 'object-path';
import hash from 'object-hash';
import { deepCopy, signal, SIGNAL_TYPES } from '../common/utils';

const DEFAULT_POSITION = {
  widget: {
    bottom: '20px',
    right: '20px',
  },
  button: {
    bottom: '25px',
    right: '25px',
  },
  teaser: {
    bottom: '20px',
    right: '20px',
  }
}

const DEFAULT_POSITION_MOBILE = {
  widget: {
    bottom: '0px',
    right: '0px',
  },
  button: {
    bottom: '5px',
    right: '5px',
  },
  teaser: {
    bottom: '0px',
    right: '0px',
  }
}

/* function deepFreeze(object) {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self

  for (const name of propNames) {
    const value = object[name];

    if (value && typeof value === "object") {
      deepFreeze(value);
    }
  }

  return Object.freeze(object);
}

deepFreeze(DEFAULT_POSITION)
deepFreeze(DEFAULT_POSITION_MOBILE) */

const themeProperties = {
  'widget.global.zindex': '9999',
  'widget.global.position.right': '',
  'widget.global.position.bottom': '',

  'widget.global.borderradius.inner': '6px',
  'widget.global.borderradius.outer': '12px',
  'widget.global.borderradius.shape': '50%',

  'widget.widget_button.background': '#0075BF', // CUSTOMER COLOR
  'widget.widget_button.textcolor': '#FFFFFF', // CUSTOMER COLOR

  'widget.header.background': '#0075BF', // CUSTOMER COLOR
  'widget.header.textcolor': '#FFFFFF', // CUSTOMER COLOR
  'widget.header.iconcolor': 'pink', // CUSTOMER COLOR same as textcolor

  'widget.operator.background': '#ffffff',
  'widget.operator.textcolor': '#222222',
  'widget.operator.avatarborder': '#ffffff',
  'widget.operator.logocolor': '#888888',

  'widget.coversation.background': '#e2e2e2',
  'widget.coversation.textcolor': '#222222',
  'widget.coversation.avatarborder': '#ffffff',
  'widget.coversation.message.textcolor': '#aaaaaa',
  'widget.coversation.message.dividercolor': '#aaaaaa',

  'messageTypes.bot.background': '#ffffff',
  'messageTypes.bot.textcolor': '#222222',
  'messageTypes.bot.button.border.color': '#aaaaaa',
  'messageTypes.userMessage.background': '#0075BF', // CUSTOMER COLOR
  'messageTypes.userMessage.textcolor': '#FFFFFF', // CUSTOMER COLOR

  'messageTypes.cards.background': '#ffffff',
  'messageTypes.cards.button.background': '#f3f3f3',
  'messageTypes.cards.button.textcolor': '#000000',
  'messageTypes.cards.button.bordercolor': '#dfdfdf',

  'messageTypes.carousel.background': '#000000',
  'messageTypes.carousel.navigation.background': '#ffffff',
  'messageTypes.carousel.navigation.textcolor': '#484848',

  'widget.teaser.bubble.background': '#ffffff',
  'widget.teaser.bubble.textcolor': '#222222',
  'widget.teaser.avatarborder.color': '#ffffff',
  'widget.teaser.qr.background': '#ffffff',
  'widget.teaser.qr.textcolor': '#222222',
  'widget.teaser.qr.border': '#ececec',
  'widget.teaser.qr.borderhover': '#aaaaaa',
  'widget.teaser.qr.icon.background': '#ffffff',
  'widget.teaser.qr.icon.color': '#888888',

  'widget.input.background': '#ececec',
  'widget.input.sendbutton.background': '#aaaaaa',
  'widget.input.sendbutton.icon': '#ffffff',

  'widget.input.textinput.background': '#ffffff',
  'widget.input.textinput.textcolor': '#222222',
  'widget.input.textinput.border': '#888888',

  'widget.qr.background': '#ffffff',
  'widget.qr.textcolor': '#222222',
  'widget.qr.border': '#aaaaaa',
  'widget.qr.borderhover': '#888888',

};

export const useWidgetStore = defineStore('widget', {
  state: () => {
    return {
      count: 0,
      // bot service // isInitialized
      botInstance: null,
      // host window / window soze and props
      global: {
        viewport: {
          width: window.parent.innerWidth,
          height: window.parent.innerHeight,
        },
      },
      // current widgets state and set props
      widget: {
        behaviour: {
          widgetOpen: null
        },
        // set via api
        zIndex: 0,
        // open / closed
        isOpen: false,
        isTyping: true,
        // previously showWidget -> hide everything via api 
        isVisible: true,
        showTeaser: false,
        global: {
          position: {
            widget: {},
            teaser: {},
            button: {}
          }
        },


        qr: [],
        teaser: {
          showAvatar: false,
          message: '👍',
          qr: [],
        },
        input: {
          inputType: 'textinput',
        },
        connectionState: {
          state: '',
          message: '',
        },
        selectedOperator: 'default',
        operator: {
          default: {
            name: '',
            titel: '',
            avatar_url: '',
          },
        },
      },
      viewport: {
        width: window.parent.innerWidth,
        height: window.parent.innerHeight
      },

      messages: [],
      config: {
        poweredBy: true,
        dontModifyHostBody: false,
      },
      theme: {},
    }
  },
  getters: {
    isOpen: (state) => state.widget.isOpen,
    isWidgetOpen: (state) => state.widget.isOpen,
    isTyping: (state) => state.widget.isTyping,
    showTeaser: (state) => state.widget.showTeaser,
    isMobile(state) { return state.global.viewport.width <= 501 },
    isMobileTeaser: (state) => state.global.viewport.width <= 768,
    getTeaser: (state) => state.widget.teaser,
    getZIndex: (state) => state.widget.zIndex,
    getWidgetHeaderName: (state) => state.config.components.widget.header.name || '',
    getWidgetBehaviour: (state) => state.widget.behaviour,
    showPoweredBy: (state) => state.config.poweredBy,
    getOperators: (state) => state.widget.operator,
    getInputType: (state) => state.config.components.widget?.input?.inputType || 'textinput', // Fall back to textinput as default
    getInputPlaceholder: (state) => state.config.components.widget?.input?.placeholder || '',
    getOperatorName: (state) => state.widget.operator[state.widget.selectedOperator]?.name || '',
    getOperatorTitel: (state) => state.widget.operator[state.widget.selectedOperator]?.titel || '',
    getDefaultAvatar: (state) => state.widget.operator?.default?.avatar_url || '',
    getOperatorAvatar: (state) => state.widget.operator[state.widget.selectedOperator]?.avatar_url || '',
    getAvatarByOperatorID: (state) => {
      return (opId) => state.widget.operator[opId]?.avatar_url || ''
    },
    getHostViewport: (state) => state.global.viewport,
    getPosition(state) {
      // let this next code line be an testiment for the hours I wasted no realizing I was using a shallow copy of the default options 
      // and the nested properties were copys of the reference and because of that i was searching for the bug and weird behaviours
      // for hours and went nearly mad

      // create defaults based on mobile / desktop
      let position = Object.assign({}, this.isMobileTeaser ? deepCopy(DEFAULT_POSITION_MOBILE) : deepCopy(DEFAULT_POSITION))
      // override the defaults with positions set via config or api 
      position.widget = Object.assign(position.widget, state.widget.global?.position?.widget)
      position.teaser = Object.assign(position.teaser, state.widget.global?.position?.teaser)
      position.button = Object.assign(position.button, state.widget.global?.position?.button)

      // There are some exceptions to the rules that beat everything
      if (this.isMobileTeaser) {
        // on mobile only the y axis is free for manipulation 
        // so we override again with the default
        position.teaser.right = DEFAULT_POSITION_MOBILE.teaser.right
        position.button.right = DEFAULT_POSITION_MOBILE.button.right

        // -> on mobile the widget fill the whole screen with the achor point bottom right 
        position.widget.bottom = DEFAULT_POSITION_MOBILE.widget.bottom
        position.widget.right = DEFAULT_POSITION_MOBILE.widget.right
      }
      return position
    },
    getThemeCSSVars: (state) => {
      const keys = Object.keys(themeProperties);
      const props = []
      keys.forEach((item) => {
        const value = state.theme[item];
        const cssvar = item.replace(/\./gi, '-');
        if (value) {
          props.push(`--kw-${cssvar}: ${value}`)
          // document.documentElement.style.setProperty(`--kw-${cssvar}`, value);
        }
      });
      return props.join(';')
    }
  },
  actions: {
    increment() {
      this.count++
    },
    setInputType(type) {
      this.widget.input.inputType = type
    },
    setWidgetOpenBehaviour(behaviour) {
      this.widget.behaviour.widgetOpen = behaviour
    },
    setVisible(visible) {
      this.widget.isVisible = visible
    },
    setConnectionState(cstate) {
      this.widget.connectionState.state = cstate.state;
      this.widget.connectionState.message = cstate.message;
      if (cstate.timeout) {
        setTimeout(() => {
          this.widget.connectionState.state = '';
        }, cstate.timeout);
      }
    },
    setScreensize(width, height) {
      this.global.viewport = {
        width, height
      }
    },
    setHistory(payload) {
      const msgs = payload.history;
      this.messages.push(...msgs.map((msg) => {
        return {
          ...msg,
          order: msg.timestamp,
        };
      }));
    },
    updateHistory(payload) {
      const msgs = payload.history.map((msg) => {
        return {
          ...msg,
          order: msg.timestamp,
        };
      });

      msgs.forEach((item) => {
        const index = this.messages.findIndex(msg => msg.key === item.key);
        if (index < 0) {
          // Danger Zone: we can't identify and match the messages from the optimistic
          // rendering to the messages coming for the history update.
          // So we we "ignore" updates that are very close to messages that are currently rendered.
          // This might break. Most likey at some time. But I think only in a VERY small amount of cases
          // we can tighten the rules: checking timestamp in a certain intervall if needed (+-100ms)
          // but this also have the risk to be too tight and we become very time depened.
          // Maybe another can of worms...  ¯\_(ツ)_/¯
          const matches = this.messages.filter((msg) => {
            return msg.data.text === item.data.text
              && msg.data.messageType === 'UserMessage'
              && msg.flag === 'optimisticUpdate';
          });
          if (matches.length <= 0) {
            console.log('PUSH ITEM', item);
            this.messages.push(item);
          }
        }
      });
    },
    openWidget() {
      if (!botService.hasConnection()) {
        botService.connect();
      }
      // FINALLY OPEN WIDGET
      this.widget.isOpen = true
      this.widget.showTeaser = false
      if (this.config.useLocalStorage) {
        window.localStorage.setItem('knowhere_widget_open', 'open');
      }
      signal(SIGNAL_TYPES.INTERACTION, {
        source: 'widget',
        action: 'open_widget',
      })
    },
    toggleWidget() {
      this.widget.isOpen ? this.closeWidget() : this.openWidget()
    },
    closeWidget() {
      this.widget.isOpen = false
      if (this.config.useLocalStorage) {
        window.localStorage.setItem('knowhere_widget_open', 'close');
      }
      signal(SIGNAL_TYPES.INTERACTION, {
        source: 'widget',
        action: 'close_widget',
      })
    },
    addMessage(message) {
      if (!message.order) {
        message.order = message.timestamp
      }
      // add message to the list
      this.messages.push(message)
      // If we get a manual user response -> clear the quick replies
      if (message.source === 'user' && this.widget.qr !== 0) {
        this.widget.qr = []
      }
    },
    addOperator(operator) {
      this.widget.operator[operator.data.opid] = operator.data;
      this.widget.selectedOperator = null;
      this.widget.selectedOperator = operator.data.opid;
    },
    setOperator(operator) {
      this.widget.selectedOperator = operator.data.opid;
    },
    setTeaser({ showAvatar, message, quickActions, timeout = 0, forceShow }) {
      // TODO TEST TEASER LOGIC, FORCESHOW && LOCALSTOREAGE TIMEOUT
      if (botService.getJWTToken() && !forceShow) {
        console.warn('active session - no teaser allowed');
        return;
      }
      const closedBefore = parseInt(window.localStorage.getItem('moinai_teaser_closeaction'), 10) || null;
      if (closedBefore // have the teaser closed before
        && !forceShow // if it's forced, we show it anyway
        && this.config.dontShowClosedTeaserFor // is there a timeout for the teaser supression set?
        && Date.now() - closedBefore < this.config.dontShowClosedTeaserFor) {
        console.warn('teaser was closed before - no teaser is shown');
        return
      }
      setTimeout(() => {
        this.widget.teaser = { showAvatar, message, qr: quickActions }
        this.widget.showTeaser = true
      }, timeout)
    },
    setPosition(bottom, right) {
      // make sure we get a not null value AND not an empty string
      // no need the check for nullish here we assume it's 0px or 0% not simply 0
      if (bottom && bottom !== '') {
        this.widget.global.position.widget.bottom = bottom
        this.widget.global.position.teaser.bottom = bottom
        this.widget.global.position.button.bottom = bottom
      }

      if (right && right !== '') {
        this.widget.global.position.widget.right = right
        this.widget.global.position.teaser.right = right
        this.widget.global.position.button.right = right
      }
    },
    closeTeaserAction() {
      this.widget.showTeaser = false
      this.widget.teaser.showAvatar = false
      if (this.config.useLocalStorage) {
        window.localStorage.setItem('moinai_teaser_closeaction', Date.now());
      } else {
        console.log("localstorage not allowed")
      }
      signal(SIGNAL_TYPES.INTERACTION, {
        source: 'teaser',
        action: 'close_teaser',
      })
    },
    handleTeaserQuickReplyClick(intent) {
      this.widget.showTeaser = false
      this.widget.isOpen = true;
      this.intent(intent)
      signal(SIGNAL_TYPES.INTERACTION, {
        source: 'teaser',
        action: 'button_click',
        text: intent,
      })
    },
    intent({ text, intent, parameters }) {
      console.log('intent', intent)
      botService.sendIntent({
        timestamp: Date.now(),
        text,
        intent,
        parameters,
      })
      this.widget.qr = []
    },
    userInput(input) {
      let order = 0;
      if (this.messages.length >= 1) {
        order = Math.max(...this.messages.map(item => item.timestamp)) + 1;
      }
      const time = Date.now();
      const key = hash(`${time}${'user'}UserMessage`);
      // TODO -> WE need to change the source the moment we support other input types
      signal(SIGNAL_TYPES.USERINPUT, { message: input, action: 'user_input', source: 'textinput' })


      const message = {
        key,
        flag: 'optimisticUpdate',
        type: 'msg',
        source: 'user',
        timestamp: time,
        order,
        data: {
          messageType: 'UserMessage',
          text: input,
        },
      };
      this.addMessage(message)
      botService.sendInput(message)
    },
    setIsTyping(typing) {
      this.widget.isTyping = typing
    },
    setQuickReplies(qrs) {
      this.widget.qr = qrs
    },
    setZIndex(zIndex) {
      console.log('set new z', zIndex)
      this.widget.zIndex = zIndex
    },
    setTheme(theme) {
      // set the theme
      const keys = Object.keys(themeProperties);
      keys.forEach((key) => {
        const value = objectPath.get(theme, key);
        if (value) {
          this.theme[key] = value;
        }
      });
      keys.forEach((item) => {
        const value = this.theme[item];
        const cssvar = item.replace(/\./gi, '-');
        if (value) {
          document.documentElement.style.setProperty(`--kw-${cssvar}`, value);
        }
      });
    },
    setConfig(config) {
      // set the config
      this.config = { ...this.config, ...config };
      // set default operator
      this.widget.operator = config.components.widget.operator
      this.widget.behaviour = config.behaviour
      this.setPosition(config?.components?.widget?.global?.position.bottom, config?.components?.widget?.global?.position.right)
      this.setTheme(config.components)
    }
  }
})