<template>
  <div class="knw_message_body knw_media" :class="[getMessageSender, getContext]">
    <VideoPlayer v-if="message.data.mediaType === 'video'"  :message="message.data" />
    <YouTubeEmbedd class="youtube" v-if="message.data.mediaType === 'youtube'" :message="message.data" />
    <img v-else :src="message.data.mediaUrl" alt="">
  </div>
</template>

<script>
import VideoPlayer from '@/components/media/VideoPlayer.vue';
import YouTubeEmbedd from '@/components/media/YouTubeEmbedd.vue';

export default {
  name: 'Media',
  props: ['context', 'message'],
  components: {
    VideoPlayer,
    YouTubeEmbedd,
  },
  computed: {
    getMessageSender() {
      return `sender_${this.message.source || 'bot'}`;
    },
    getContext() {
      return this.context;
    },
  },
};
</script>

<style lang="scss" scoped>
.knw_message_body{
  display: flex;
  margin-bottom: 5px;
  &.knw_media {
    padding: 0 35px 0 57px;
    img, video{
      border-radius: var(--kw-widget-global-borderradius-inner);
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
      object-fit: cover;
    }

  }

  &.flow{
    img{
      height: 0%; //was 300px, should work with 0% to fix box model transisitons in safari when height is not known
      max-height: 200px;
      // width: 80%;
      width: 100%;
      margin: 0 auto;

    }

    video {
      // max-width: 80%;
      margin: 0 auto;
      object-fit: cover;
    }
  }


  span{
    display: inline-flex;
    border-radius: 0 10px 10px 10px;
    background: white;
    padding: 8px;
  }

  &.sender_user{
    justify-content: flex-end;
    span{
      border-radius: 10px 0 10px 10px;
      background-color: var(--kw-message-user-background);
    }
  }
}

.youtube{
  min-height: 200px;
}
</style>
