import { setOperator, addOperator } from '@/common/commands';
import { textMessage, buttons, image, } from '@/common/messages';
import bot from '@/services/bot';

class Zendesk {
  constructor({
    payload,
  }) {
    this.type = payload.type;
    this.payload = payload;
    this.account_key = payload.account_key;
    this.default_department = parseInt(payload.default_department);
    this.tags = payload.tags;
    this.receivedImages = [];
    this.active = true;
    this.agentCounter = 0;
    this.agentUpdatesRegistered = false;
    console.log(`init livechat object for type ${payload.type}`, this.account_key, payload);
  }

  async initLiveChat(initLivechat) {
    const {
      success,
    } = await new Promise((resolve) => {
      if (initLivechat) {
        this.active = true;
        window.zChat.init({
          account_key: this.account_key,
        });
      }

      // timeout handling because zendesk has no proper errors that we can catch and handle
      // this handles for example wrong access_key values
      let isTimeout = true;
      setTimeout(() => {
        if (isTimeout) {
          resolve({
            success: window.zChat.getConnectionStatus() === 'connected',
            start: false,
          });
        }
      }, 3500);

      window.zChat.on('connection_update', (conStatus) => {
        console.log('connection_update', conStatus);
        isTimeout = false;
        if (this.active && conStatus === 'connected') {
          window.zChat.on('agent_update', this.handleAgendUpdates);
          this.agentUpdatesRegistered = true;

          if (this.default_department) {
            window.zChat.setVisitorDefaultDepartment(this.default_department, (err, res) => {
              resolve({
                success: true,
                start: false,
              });
            });
          } else {
            resolve({
              success: true,
              start: false,
            });
          }
        }
      });
    });
    return {
      success,
    };
  }

  async setVisitorInfo(visitorInfo) {
    return new Promise(resolve => {
      console.log("in setInfo promise");
      window.zChat.setVisitorInfo(visitorInfo, () => {
        console.log("in setInfo promise resolve");
        resolve()
      });
    });
  }

  async startLivechat({
    text,
  }) {
    if (!window.zChat) {
      return {
        error: true,
        message: 'SDK not initialized',
      };
    }

    if (window.knowhere.context.user_email) {
      const visitorInfo = {
        phone: window.knowhere.context.user_phone || '',
        display_name: window.knowhere.context.user_name || '',
        email: window.knowhere.context.user_email,
      };
      console.log("call this.setVisitorInfo(visitorInfo)");
      await this.setVisitorInfo(visitorInfo);
    }

    const link = `https://hub.moin.ai/#/chats/messaging/${window.knowhere.UUID}`;

    const faq_message = window.knowhere.AskedQuestion;
    const intent_string = window.knowhere.Topics;

    const Name = window.knowhere.user_name;
    const codeOrder = window.knowhere.takeover_details;
    let message = `
      This is a Chatbot takeover.

      Original Question: ${faq_message}

      Topics the user asked about: ${intent_string}

      See the full transcript here ${link}
      `;


    if (Name) {
      message += ` 
      Name: ${Name}`;
    }
    if (codeOrder) {
      message += `
      Window code/Order number: ${codeOrder}`;
    }


    // TODO: remove this when feature ready
    if (window.knowhere.context.channel_08bfcb48eaba9047148acd8722522ad45d74d8c4c360c9fde098d9015102a134
      || window.knowhere.context['channel_test-1d8af2c93bdf9602986879138b69f43c48d16ba17c89e104e1db90bdf4d74eab'] || window.knowhere.context['channel_bls-bahn']) {
      message = `
          Dies ist ein Chatbot Takeover.
    
          Letzte Anfrage: ${faq_message}
    
          Themen: ${intent_string}
    
          Bisherige Unterhaltung öffnen: ${link}
          `;

      if (window.knowhere.context.user_anfrage) {
        message += `
        Anfrage: ${window.knowhere.context.user_anfrage}`;
      }
      if (window.knowhere.context.user_name) {
        message += `
       Name: ${window.knowhere.context.user_name}`;
      }
      if (window.knowhere.context.user_email) {
        message += `
       E-Mail: ${window.knowhere.context.user_email}`;
      }
      if (window.knowhere.context.user_telefonnummer) {
        message += `
       Tel: ${window.knowhere.context.user_telefonnummer}`;
      }
      if (window.knowhere.context.user_dimension) {
        message += `
       Dimension: ${window.knowhere.context.user_dimension}`;
      }
      if (window.knowhere.context.user_datum) {
        message += `
       Datum: ${window.knowhere.context.user_datum}`;
      }
      if (window.knowhere.context.user_kartennummer) {
        message += `
        Karten-Nr: ${window.knowhere.context.user_kartennummer}`;
      }
      if (window.knowhere.context.user_ticketnummer) {
        message += `
        Ticketnummer: ${window.knowhere.context.user_ticketnummer}`;
      }
      if (window.knowhere.context.user_intent_string) {
        message += `
        Themen: ${window.knowhere.context.user_intent_string}`;
      }
    }


    const {
      success,
    } = await this.sendMessage(message, true);

    await this.registerEventHandlers();

    return {
      success: true,
    };
  }

  async stopLivechat() {
    const {
      success,
    } = await new Promise((resolve) => {
      this.unregisterEventHandlers();
      window.zChat.endChat({
        clear_dept_id_on_chat_ended: true,
      }, (err) => {
        resolve({
          success: true,
          status: 'connected',
        });
      });
    });
    return {
      success,
    };
  }

  async isAgentAvailable() {
    if (!window.zChat) {
      return {
        available: false,
        error: true,
        message: 'SDK not initialized',
      };
    }

    if (this.default_department) {
      const departments = window.zChat.getAllDepartments();
      let status = 'offline';
      departments.forEach((dep) => {
        if (dep.id === this.default_department) {
          status = dep.status;
        }
      });

      return {
        available: status === 'online',
      };
    }
    return {
      available: window.zChat.getAccountStatus() === 'online',
    };
  }

  async sendMessage(message, first) {
    window.zChat.sendChatMsg(message);
    if (first) {
      const {
        success,
      } = await new Promise((resolve) => {
        window.zChat.addTags(this.tags, (err) => {
          setTimeout(() => {
            window.zChat.sendChatComment('From this point on the user awaits your reply. The bot wont interfere until you end the chat.');
          }, 500);
          resolve({
            success: true,
          });
        });
      });

      return {
        success,
      };
    }
    return {
      sucess: true,
    };
  }


  async registerEventHandlers() {
    window.zChat.on('chat', this.handleMessages.bind(this));
    // can happen if more than one livechat on a single zendesk connected session
    // why? ... who knows
    if (!this.agentUpdatesRegistered) {
      window.zChat.on('agent_update', this.handleAgendUpdates);
    }
    return true;
  }

  async unregisterEventHandlers() {
    this.active = false;
    window.zChat.un('chat', this.handleMessages);
    window.zChat.un('agent_update', this.handleAgendUpdates);
    window.zChat.un('connection_update');
  }


  handleAgendUpdates(update) {
    console.log('handleAgendUpdates', update);
    bot.livechatCommand(addOperator({
      opid: 'livechat',
      name: update.display_name,
      titel: update.title,
      avatar_url: update.avatar_path,
    }));

    bot.livechatCommand(setOperator('livechat'));
  }


  async handleMessages(message) {
    console.log('message', message.type, message);
    if (this.active && message.type === 'chat.msg' && message.nick.includes('agent')) {
      const { msg } = message;

      // look for urls to append as buttons to the message
      const buttonUrls = msg.match(/(https?:\/\/[^\s]+)/g);
      const agentMsg = await textMessage({
        timestamp: Date.now(),
        text: msg,
        source: 'agent',
        operator: 'livechat',
      });
      /* PubSub.publish(topics.LIVECHAT, agentMsg); */
      bot.livechatMessage(agentMsg);


      if (buttonUrls) {
        const btns = buttonUrls.map(url => ({
          type: 'web_url',
          url,
          title: url,
          payload: undefined,
        }));

        const btnmsg = await buttons(btns, Date.now(), 'agent');
        bot.livechatMessage(btnmsg);
        // PubSub.publish(topics.LIVECHAT, buttonMsg);
      }
    } else if (this.active && message.type === 'chat.file' && message.nick.includes('agent')) {
      const msg = {
        url: message.attachment.url,
      };

      // why is this needed?
      // because zopim/zendesk chat is ... weird. the image is sent twice in 90% of all cases
      // time between images is sometimes less than 50ms so there has to be a bug on their end somewhere
      // we fix this by remembering what they already sent us
      // note: this will lead to images not beeing able to be sent twice (even legitimately)
      if (!this.receivedImages.includes(msg.url)) {
        this.receivedImages.push(msg.url);
        const img = await image(msg, Date.now(), 'agent');
        /* const agentMsg = await messageService.messages.image(msg, {
          timestamp: Date.now(),
          source: 'agent',
          messageType: 'Media',
}); */
        bus.message(img);
        bot.livechatMessage(img);
      } else {
        // duplicate image, do nothing...
      }
    } else if (this.active && message.type === 'chat.memberjoin' && message.nick.includes('agent')) {
      this.agentCounter += 1;
    } else if (this.active && message.type === 'chat.memberleave' && message.nick.includes('agent')) {
      this.agentCounter -= 1;

      // agent left the chat and closed it, return to bot
      // we cant call this directly because if its an agent switch the agent leaves before the other joins
      setTimeout(() => {
        if (this.agentCounter === 0) {
          bot.sendIntent({
            intent: 'livechat_ended_by_agent',
          });
        }
      }, 750);
    }
  }
}

export default Zendesk;
