<template>
  <div class="card">
    <div class="image-wrapper">
      <VideoPlayer v-if="getMediaType(message) === 'video'" :message="message" />
      <YouTubeEmbedd v-else-if="getMediaType(message) === 'youtube'" :message="message" />
      <img v-else :src="message.imageUrl" alt />
    </div>
    <div class="card-body">
      <h1>{{ message.headline }}</h1>
      <p>{{ message.text }}</p>
    </div>
    <div class="card-actions">
      <div v-for="(button, index) in getActions" :key="index">
        <a
          class="btn"
          tabindex="-1"
          target="_blank"
          rel="nofollow"
          v-if="button.type === 'web_url'"
          @click="handleButtonClick(button)"
          :href="button.url"
        >
          <span>
            {{ button.title }}
            <span class="linkicon">
              <IconExternalLink class="btn_link" width="10px" height="10px" />
            </span>
          </span>
        </a>
        <a
          class="btn"
          target="_blank"
          rel="nofollow"
          v-if="button.type === 'url_postback'"
          :href="button.payload.url"
          @click="handleButtonClick(button)"
        >
          <span>
            {{ button.title }}
            <IconExternalLink class="btn_link" width="10px" height="10px" />
          </span>
        </a>
        <a
          class="btn"
          v-if="button.type === 'mail'"
          :href="getMailTo(button.email)"
          @click="handleButtonClick(button)"
          tabindex="-1"
        >
          <span>{{ button.text }}</span>
        </a>
        <a
          class="btn"
          v-if="button.type === 'tel'"
          :href="getPhoneNumber(button.number)"
          @click="handleButtonClick(button)"
          tabindex="-1"
        >
          <span>{{ button.text }}</span>
        </a>
        <button
          class="btn"
          v-if="button.type === 'postback'"
          tabindex="-1"
          @click="handleButtonClick(button)"
        >
          <span>{{ button.title }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import VideoPlayer from '@/components/media/VideoPlayer.vue';
import YouTubeEmbedd from '@/components/media/YouTubeEmbedd.vue';
import IconExternalLink from '@/svg/IconExternalLink.vue';
import { mapStores } from 'pinia';
import { useWidgetStore } from '@/store/widget';
import { signal, SIGNAL_TYPES } from '../../common/utils';

export default {
  name: 'card',
  props: ['message', 'cardIndex'],
  components: {
    IconExternalLink,
    VideoPlayer,
    YouTubeEmbedd,
  },
  computed: {
    ...mapStores(useWidgetStore),
    getActions() {
      return this.message.actions;
    },
  },
  methods: {
    getMediaType(message) {
      if (
        message.imageUrl.endsWith('avi') ||
        message.imageUrl.endsWith('mov') ||
        message.imageUrl.endsWith('mp4')
      ) {
        return 'video';
      }
      if (message.imageUrl.indexOf('https://www.youtube.com') !== -1) {
        return 'youtube';
      }
      return 'image';
    },
    reportFocus() {
      this.$emit('tabbedFocus', this.cardIndex);
    },
    getPhoneNumber(number) {
      return `tel:${number}`;
    },
    getMailTo(adress) {
      return `mailto:${adress}`;
    },
    handleButtonClick(context) {
      /* if (context.type === 'web_url') return; */
      const target = context.url || context.number || context.email;
      const data = {
        source: 'card',
        intent: context?.payload?.name,
        text: context.title,
        target,
        type: context.type,
        parameters: context?.payload?.parameters,
      };

      signal(SIGNAL_TYPES.CLICK, data)
      // only signal and send Intent on buttons -> postback and url_postback
      if (context.type === 'postback' || context.type === 'url_postback') {
        this.widgetStore.intent(data)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: grid;
  background: var(--kw-messageTypes-card-background);
  width: 100%;
  grid-template-rows: 150px 1fr auto;
  border-radius: var(--kw-widget-global-borderradius-inner);
  overflow: hidden;
  padding-bottom: 15px;
  margin-top: 0px;
  .image-wrapper {
    pointer-events: all;
    cursor: grab;
    img {
      pointer-events: none;
      width: 100%;
      height: 150px;
      object-fit: cover;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }
  .videoplayer {
    height: 150px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.card-body {
  padding: 0 15px;
  align-self: start;
  padding-top: 10px;
  h1,
  h2,
  h3 {
    margin: 5px 0;
    color: black;
    @include font_headline;
    font-weight: 500;
  }
  p {
    margin-top: 0;
  }
}

.card-actions {
  display: flex;
  padding: 0 15px;
  flex-direction: column;
  justify-content: flex-end;
  /* button{
    margin-bottom: 4px;
    background: transparent;
    border: 1px solid black;
    border-radius: 4px;
    padding: 8px 20px;
    color: black;
    justify-self: flex-end;
    @include font_chat_text;
  } */
  @include kw_btn;
  .btn {
    background-color: var(--kw-messageTypes-card-button-background);
    border: 1px solid var(--kw-messageTypes-card-button-border);
    color: var(--kw-messageTypes-card-button-textcolor);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    margin: 0;
    margin-bottom: 7px;
    font: 16px/22px Rubik, Helvetica, Arial, sans-serif;
    font-size: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
    &:hover {
      cursor: pointer;
      border: 1px solid $kw-default-button-border-color-hover;
      // background: $kw-global-background-lightgrey;
      @include default-focus;
    }
  }
}
</style>
