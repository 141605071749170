<template>
  <div :style="getGlobalTheme">
    <Teaser v-if="widgetStore.showTeaser" @close="handleClosebutton" @quickreply="handleQuickReplyClick"
      :teaser="widgetStore.getTeaser" v-model="teaserDimensions" :viewportWidth="getHostViewport.width" />
  </div>
</template>

<script>
import { mapStores, mapState } from 'pinia'
import { useWidgetStore } from '@/store/widget'
import Teaser from '@/components/notifications/Teaser.vue';
import { setStyles } from '../../common/utils';

export default {
  props: ['modelValue', 'frame'],
  data() {
    return {
      teaserDimensions: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    ...mapStores(useWidgetStore),
    ...mapState(useWidgetStore, ['showTeaser', 'isMobileTeaser', 'getZIndex', 'getPosition', 'getHostViewport']),
    getGlobalTheme() {
      return this.widgetStore.getThemeCSSVars
    },
  },
  watch: {
    showTeaser() {
      // inital width to 400px that we can calc the needed width for the teaser
      // which will be set on the teaserDimensions watcher 
      Object.assign(this.frame.style, { width: '400px', height: '400px' });
      this.calcFrameStyles()
    },
    getPosition() {
      this.calcFrameStyles()
    },
    getZIndex() {
      this.calcFrameStyles()
    },
    teaserDimensions(next) {
      //this.$emit('update:modelValue', next);
      // console.log('setTeaserDimensions', this.teaserDimensions.width, this.teaserDimensions.height)
      // move into the general calc styles
      this.calcFrameStyles()

    },
  },
  components: {
    Teaser,
  },
  beforeMount() {
    this.calcFrameStyles()
    // 
    Object.assign(this.frame.style, { width: '400px', height: '400px' });
  },
  methods: {
    handleClosebutton() {
      this.widgetStore.closeTeaserAction()
    },
    calcFrameStyles() {
      const styles = {
        pointerEvents: 'all!important',
        position: 'absolute!important',
        border: 'none!important',
        maxWidth: '100vw!important',
        maxHeight: 'unset!important',
        right: `${this.getPosition.teaser.right}!important`,
        bottom: `${this.getPosition.teaser.bottom}!important`,
        left: this.isMobileTeaser ? '0!important' : 'unset!important',
        width: this.isMobileTeaser ? '100%!important' : `${this.teaserDimensions.width}!important`,
        height: `${this.teaserDimensions.height + 22}px!important`,
        background: 'transparent',
        zIndex: `${this.getZIndex}!important`
      }
      if (!this.showTeaser) {
        styles.width = '1px!important'
        styles.height = '1px!important'
        styles.maxWidth = '1px!important'
        styles.maxHeight = '1px!important'
        styles.pointerEvents = 'none!important'
      }
      setStyles(this.frame, styles)
    },
    handleQuickReplyClick(payload) {
      this.widgetStore.handleTeaserQuickReplyClick(payload)
    },
  },
};
</script>

<style lang="scss">
</style>

