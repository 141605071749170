<template>
  <div class="bodywrapper" ref="bodywrapper">
    <div class="knw_widget_body" ref="knw_widget_body" id="knw_widget_body" :style="snapType">
      <div class="knw_messageList" ref="knw_messageList">
        <!-- <span
          :style="{ 'z-Index': 5000, position: 'sticky', top: '20px', background: '#33FFFF' }"
        >scrolling:{{ isScrolling }} userScroll:{{ userScroll }}</span>-->
        <TypingIndicator key="typing" :typing="widgetStore.isTyping" />
        <transition-group name="list" @after-enter="onEnter">
          <component
            v-for="(message, index) in messages"
            :is="comps[message.data.messageType]"
            :id="`${message.key}`"
            :key="`${message.key}`"
            context="flow"
            :message="message"
            :data-source="message.source"
            :sequenz="
              getPositionWithinSequenz(messages[index - 1], message, messages[index + 1])
            "
          ></component>
        </transition-group>
      </div>
    </div>
  </div>
</template>
<script setup>
import { sortBy } from "underscore";
import TypingIndicator from "@/components/TypingIndicator.vue";
import TextMessage from "@/components/messages/TextMessage.vue";
import UserMessage from "@/components/messages/UserMessage.vue";
import ButtonList from "@/components/messages/ButtonList.vue";
import Media from "@/components/messages/Media.vue";
import Carousel from "@/components/messages/Carousel.vue";
import CoversationMessage from "@/components/messages/CoversationMessage.vue";
import { mapStores } from 'pinia'
import { useWidgetStore } from '@/store/widget'
import { computed, ref, watch, onMounted } from "vue";
import { useScroll } from '@vueuse/core'

const widgetStore = useWidgetStore()
const userScroll = ref(false)

const knw_widget_body = ref(null)
const knw_messageList = ref(null)
const { x, y, isScrolling, arrivedState } = useScroll(knw_widget_body, {
  onScroll: (event) => {
    if (isScrolling.value && !arrivedState.bottom) userScroll.value = true
    if (isScrolling.value && arrivedState.bottom) userScroll.value = false
  }
})
/* watch(isScrolling, () => {
  if (isScrolling.value && !arrivedState.bottom) {
    userScroll.value = true
  }
  if (!isScrolling.value && arrivedState.bottom) {
    userScroll.value = false
  }
  console.log(isScrolling.value, arrivedState.bottom, userScroll.value)
}); */
/* onMounted(() => {
}) */

const scrollHeight = computed(() => {
  return knw_messageList?.value?.clientHeight
})

const snapType = computed(() => {
  // console.log(y.value, knw_messageList?.value?.scrollHeight, knw_widget_body?.value?.clientHeight + y.value)
  if (userScroll.value) {
    return { 'scroll-snap-type': 'none' };
  } else {
    return { 'scroll-snap-type': 'y proximity' };
  }
})


const comps = {
  TextMessage,
  Media,
  Carousel,
  UserMessage,
  ButtonList,
  CoversationMessage
}

const onEnter = () => {
  // console.log('scroll to end', knw_widget_body.value.scrollHeight, knw_messageList?.value?.clientHeight, arrivedState.bottom, userScroll.value, knw_widget_body.value.scrollTopMax)
  setTimeout(() => {
    knw_widget_body.value.scrollTo({
      top: knw_messageList?.value?.clientHeight,
      behavior: 'smooth'
    })
  }, 10)
}

const getPositionWithinSequenz = (prev, current, next) => {
  // this breaks my brain right now, but should(tm) be now correct
  const isDev = process.env.NODE_ENV === "development";
  let position = isDev ? "SHOULD NOT HAPPEN" : "";
  if (!prev) return "first";
  if (!prev && next && next.source === current.source) position = "start";
  if (prev && !next && prev.source === current.source) position = "end";
  if (prev && !next && prev.source !== current.source) position = "single";
  if (prev && next) {
    if (prev.source !== current.source && next.source === current.source) position = "start";
    if (prev.source === current.source && next.source !== current.source) position = "end";
    if (prev.source === current.source && next.source === current.source) position = "middle";
    if (prev.source !== current.source && next.source !== current.source) position = "single";
  }
  return position;
}


const messages = computed(() => {
  return sortBy(widgetStore.messages, item => item.order);
})

/* const getMessages = () => {
  return sortBy(this.widgetStore.messages, item => item.order);
} */

/* 
export default {
  name: "widgetBody",
  components: {
    TypingIndicator,
    TextMessage,
    Media,
    Carousel,
    UserMessage,
    ButtonList,
    CoversationMessage
  },
  data() {
    return {
      sequenz: Math.round(Math.random() * 1000)
    };
  },
  computed: {
    ...mapStores(useWidgetStore),
    getMessages() {
      return sortBy(this.widgetStore.messages, item => item.order);
    },
    scrollFireFoxFix() {
      
      if (navigator.userAgent.indexOf("Firefox") > 0) {
        try {
          const ffversion = /Firefox\/([0-9][0-9])/i;
          const version = parseInt(ffversion.exec(navigator.userAgent)[1], 10);
          console.log(version);
          if (version <= 81) {
            return { "min-height": "100%" };
          }
        } catch (error) {
          return "";
        }
      }
      return "";
    }
  },
  methods: {
    getPositionWithinSequenz(prev, current, next) {
      // this breaks my brain right now, but should(tm) be now correct
      const isDev = process.env.NODE_ENV === "development";
      let position = isDev ? "SHOULD NOT HAPPEN" : "";
      if (!prev) return "first";
      if (!prev && next && next.source === current.source) position = "start";
      if (prev && !next && prev.source === current.source) position = "end";
      if (prev && !next && prev.source !== current.source) position = "single";
      if (prev && next) {
        if (prev.source !== current.source && next.source === current.source) position = "start";
        if (prev.source === current.source && next.source !== current.source) position = "end";
        if (prev.source === current.source && next.source === current.source) position = "middle";
        if (prev.source !== current.source && next.source !== current.source) position = "single";
      }
      return position;
    },
    scrollToEnd() {
      // scroll to the start of the last message
      if (this.$refs.knw_widget_body) {
        //this.$refs.bodywrapper.scrollTop = this.$refs.knw_messageList.$el.scrollHeight;
      }
    }
  },
  updated() {
    this.$nextTick(() => this.scrollToEnd());
  },
  watch: {
    getMessages: {
      handler(val, oldVal) {
        this.scrollToEnd();
      },
      deep: true
    }
  }
}; */
</script>

<style lang="scss" scoped>
.bodywrapper {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.knw_widget_body {
  position: relative;
  max-width: 100%;
  max-width: 500px;
  width: 100%;
  // background-color: var(--kw-messagecontainer-background);
  background-color: $kw-global-background-messagecontainer;
  padding-top: 10px;
  align-self: end;
  transition: flex 0.25s ease-out;
  flex: 1;

  @media (min-width: 501px) {
    max-width: 461px;
  }
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  scroll-snap-type: y proximity;
  position: absolute;
  //scroll-behavior: smooth;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  > .knw_messageList {
    /* padding-bottom: 50px; */
    position: relative;
    background-color: $kw-global-background-messagecontainer;
    padding-left: 5px;
    padding-right: 5px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    & > :last-child {
      scroll-snap-align: end;
      scroll-margin-block-end: 5rem;
      margin-bottom: 50px;
    }
  }
}

// list transitions
.list-enter-from {
  opacity: 0;
  // transform: translateX(0px) scaleY(0) scaleX(0.9);
  &.sender_bot {
    // transform: translateX(0px) scaleY(0) scaleX(0.9);
  }
}
.list-enter-active {
  opacity: 0;
  transform-origin: top left;
  transition: transform 0.25s, opacity 0.25s ease-in-out 0.25s;
  &.sender_bot {
    transform-origin: top right;
  }
}
.list-enter-to {
  opacity: 1;
  // transform: translateX(0) scaleY(1) scaleX(1);
}

.list-move {
  /*  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);  */
  transition: 0.5s all;
}

.list-leave-from {
  // ...
}
.list-leave-active {
  transition: 0.5s all;
  position: absolute;
}
.list-leave-to {
  opacity: 0;
  transform: scale(0);
  transform-origin: center top;
}
</style>
