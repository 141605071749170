<template>
  <div class="knw_widget_inputbar" key="textinput">
    <span class="charCounter" :class="getCharCountColor" v-if="showCharCount">{{ charCount }}</span>
    <div class="knw_widget_inputbar_textarea">
      <AutosizeTextAreaVue
        rows="1"
        :maxlength="this.maxCharCount"
        v-model="message"
        :placeholder="getPlaceholder"
        :max-height="maxheight"
        ref="textinput"
        :class="{ bordered: showSendButton }"
        @focus="setFocus"
        @input="countChars"
        @blur="releaseFocus"
        @keydown.enter.exact.prevent
        @keyup.enter.exact="handleUserInput"
        @keydown.enter.shift.exact="newline"
      />
      <!-- <textarea
        rows="1"
        :maxlength="this.maxCharCount"
        v-model="message"
        :placeholder="getPlaceholder"
        :max-height="maxheight"
        ref="textinput"
        :class="{ bordered: showSendButton }"
        @focus="setFocus"
        @input="countChars"
        @blur="releaseFocus"
        @keydown.enter.exact.prevent
        @keyup.enter.exact="handleUserInput"
        @keydown.enter.shift.exact="newline"
      ></textarea>-->
      <transition name="fadeIn">
        <button
          @click="handleUserInput"
          class="knw_widget_inputbar_btn"
          :class="{ showButton: showSendButton }"
        >
          <IconSendButton />
        </button>
      </transition>
    </div>
  </div>
</template>

<script>
import IconSendButton from "@/svg/IconSendButton.vue";
import { mapStores } from 'pinia'
import { useWidgetStore } from '@/store/widget'
import AutosizeTextAreaVue from "./AutosizeTextArea.vue";

export default {
  name: "textinput",
  props: {
    maxCharCount: {
      default: 256
    }
  },
  components: {
    IconSendButton,
    AutosizeTextAreaVue
  },
  data() {
    return {
      maxheight: 40,
      message: "",
      focus: false,
      charCount: 0
    };
  },
  computed: {
    ...mapStores(useWidgetStore),
    getPlaceholder() {
      return this.widgetStore.getInputPlaceholder;
    },
    showSendButton() {
      return this.message !== "" || this.focus;
    },
    showCharCount() {
      return this.charCount > 150;
    },
    getCharCountColor() {
      if (this.charCount < 240) return "";
      if (this.charCount > 240) return "nearMaxCount";
      return "";
    }
  },
  updated() { },
  mounted() {
    this.$nextTick(() => {
      this.focusInputElement();
    });
  },
  methods: {
    focusInputElement() {
      // iOS Hack to scroll down to the input element
      // not pretty and scrolls the window in the background
      // but currently I can't find a propper way to deal with that.
      if (window?.parent?.innerWidth >= 550) {
        // TODO THIS SEEMS NOT TO WORK
        this.$refs.textinput.focus();
      }
    },
    newline() {
      this.message = `${this.message}\n`;
    },
    countChars() {
      // we need to prevent the initial "too big textarea" because of the faulty height calc inside of iframes
      this.maxheight = 100;

      this.charCount = this.$refs.textinput.modelValue.length;
    },
    setFocus() {
      this.focus = true;
    },
    releaseFocus() {
      this.focus = false;
    },
    handleUserInput() {
      if (this.message.trim() !== "") {
        this.widgetStore.userInput(this.message)
        this.message = "";
        this.charCount = 0;
      }
    }
  }
};
</script>

<style lang="scss">
.knw_widget_inputbar {
  position: relative;
  display: block;
  z-index: 100;
  min-height: 50px;
  margin: 3px 0;
  .knw_widget_inputbar_textarea {
    background: $kw-global-background-input;
    display: flex;
    align-items: center;
    width: 100%;
  }

  textarea {
    overflow-y: auto;
    border: none;
    background: $kw-global-background-white;
    resize: none;
    width: 100%;
    max-height: 85px;
    min-height: 0;
    font: 16px "Rubik", "Helvetica", "Arial", sans-serif;
    color: #333;
    margin: 0 4px 0 0;
    padding: 10px 15px;
    border: 1px solid rgba(150, 150, 150, 0);
    border-radius: 20px;
    transition: border 0.15s, height 0.15s;
    @include default-focus;
    outline: none !important;
    &.bordered {
      transition: border 0.15s, height 0.15s;
      border: 1px solid rgba(150, 150, 150, 0);
      background-color: $kw-global-background-white;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &::placeholder {
      color: $kw-global-textcolor-medium;
    }
  }

  .knw_widget_inputbar_btn {
    align-self: flex-end;
    width: 38px;
    height: 38px;
    margin-bottom: 1px;
    margin-left: 2px;
    background: transparent;
    border: none;
    padding: 0 0 0 0;
    font-size: 16px;
    opacity: 0.75;
    transition: all $kw-default-transition-speed-fast ease-out;
    transform: scale(1);
    @include default-focus;
    border-radius: 50%;
    /*    transform: translateX(10px);*/
    &.showButton {
      opacity: 1;
      /*      transform: translateX(0px);*/
    }
    &:hover,
    &:focus {
      cursor: pointer;
      transform: scale(1.1);
      opacity: 1;
    }
  }

  > svg {
    width: 35px;
    height: 35px;
    max-width: 35px;
    max-height: 35px;
    display: block;
  }
}

.charCounter {
  position: absolute;
  text-align: center;
  width: 40px;
  right: 8px;
  top: 10px;
  font-size: 14px;
  padding: 0 5px;
  color: $kw-global-textcolor-medium;
  transition: color 0.4s ease;
  &.nearMaxCount {
    // color: $kw-global-textcolor-medium;
    color: darkred;
  }
}
</style>
