<template>
  <div id="knw_widget" class="knw_widget" v-if="showWidget">
    <transition name="window" appear>
      <div class="knw_widget_window" v-if="isWidgetOpen">
        <WidgetHeader />
        <WidgetOperator ref="knw_widget_header" />
        <WidgetBody />
        <WidgetInput />
      </div>
    </transition>

    <teleport :to="buttonPortalTarget">
      <transition name="widgetbutton" mode="out-in" appear>
        <PortalButton v-if="!isWidgetOpen" :frame="buttonFrame" />
      </transition>
    </teleport>
    <teleport :to="teaserPortalTarget">
      <PortalTeaser v-if="!isWidgetOpen" :frame="teaserFrame" />
    </teleport>
  </div>
</template>

<script>
import { mapStores, mapState } from 'pinia'
import { useWidgetStore } from './store/widget'
import WidgetHeader from "@/components/WidgetHeader.vue";
import WidgetBody from "@/components/WidgetBody.vue";
import WidgetOperator from "@/components/WidgetOperator.vue";
import WidgetInput from "@/components/WidgetInput.vue";

import PortalButton from "@/components/portalElements/PortalButton.vue";
import PortalTeaser from "@/components/portalElements/PortalTeaser.vue";
import { setStyles } from './common/utils';

export default {
  name: "knowhere-widget",
  components: {
    WidgetHeader,
    WidgetOperator,
    WidgetInput,
    WidgetBody,
    PortalButton,
    PortalTeaser
  },
  data() {
    return {
      widgetFrame: window.parent.document.getElementById("moin-ai-widget"),
      buttonFrame: window.parent.document.getElementById("moin-ai-button"),
      buttonPortalTarget: window.parent.document.getElementById("moin-ai-button")
        .contentDocument.getElementById("moinai-container"),
      teaserFrame: window.parent.document.getElementById("moin-ai-teaser"),
      teaserPortalTarget: window.parent.document.getElementById("moin-ai-teaser")
        .contentDocument.getElementById("moinai-container"),
      scrollPosition: window.parent.document.pageYOffset,
    };
  },
  computed: {
    ...mapStores(useWidgetStore),
    ...mapState(useWidgetStore, ['getPosition', 'getZIndex', 'isMobile']),
    isWidgetOpen() {
      return this.widgetStore.isOpen
    },
    lockScroll() {
      return this.widgetStore.widget.isOpen && window.innerWidth <= 550;
    },
    showWidget() {
      return this.widgetStore.widget.isVisible;
    },
    showTeaser() {
      return this.widgetStore.showTeaser;
    },
    hostScreenSize() {
      return this.widgetStore.getHostViewport;
    }
  },
  methods: {
    enableScrollLock() {
      const body = window.parent.document.body;
      this.scrollPosition = window.parent.document.pageYOffset;
      body.classList.add('knowhere-ScrollLock');
      body.style.top = `-${this.scrollPosition}px`;
    },
    disableScrollLock() {
      const body = window.parent.document.body;
      body.classList.remove('knowhere-ScrollLock');
      body.style.removeProperty('top');
      window.parent.scrollTo(0, this.scrollPosition);
    },
    calcFrameStyles() {
      const styles = {
        background: 'transparent!important',
        position: 'absolute!important',
        border: 'none!important',
        width: this.isMobile ? '100%!important' : '501px!important',
        height: this.isMobile ? 'calc(var(--knowhere-vh, 1vh) * 100)' : '740px!important',
        right: `${this.getPosition.widget.right}!important`,
        bottom: `${this.getPosition.widget.bottom}!important`,
        zIndex: `${this.getZIndex}!important`,
        pointerEvents: 'all!important',
        maxHeight: this.isMobile ? 'unset' : `calc(var(--knowhere-vh, 1vh) * 98 - ${this.getPosition.widget.bottom})`
      }

      if (!this.isWidgetOpen) {
        styles.width = '1px!important'
        styles.height = '1px!important'
        styles.bottom = '0px!important';
        styles.right = '0px!important';
        styles.pointerEvents = 'none!important'
      }
      setStyles(this.widgetFrame, styles)
    },
    updateScollLock() {
      if (this.widgetStore.config.useScrollLock) {
        const isLocked = window.parent.document.body.classList.contains('knowhere-ScrollLock');
        if (this.widgetStore.widget.isOpen && (this.widgetStore.getHostViewport.width <= 500)) {
          if (!isLocked) this.enableScrollLock();
        } else {
          if (isLocked) this.disableScrollLock();
        }

      }
    }
  },
  watch: {
    hostScreenSize(next) {
      // we need this hack to display propperly on Andriod devices
      // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
      var vh = window.parent.innerHeight * 0.01;
      window.parent.document.body.style.setProperty('--knowhere-vh', `${vh}px`);
      // if the widget is open we need to scroll lock the body
      // if the dontModifyHostBody flag is set the customer dont want us to interfer with the host html
      this.updateScollLock()
    },
    isWidgetOpen(nextVal) {
      this.calcFrameStyles()
      this.updateScollLock()
    },
    getPosition(position) {
      this.calcFrameStyles()
    },
    getZIndex(zIndex) {
      this.calcFrameStyles()
    }
  },
  beforeMount() {
    this.calcFrameStyles()
  },
};
</script>

<style lang="scss">
/* rubik-regular - latin */

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/rubik-v9-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Rubik"), local("Rubik-Regular"),
    url("./assets/fonts/rubik-v9-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./assets/fonts/rubik-v9-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("./assets/fonts/rubik-v9-latin-regular.woff") format("woff"),
    /* Modern Browsers */
    url("./assets/fonts/rubik-v9-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("./assets/fonts/rubik-v9-latin-regular.svg#Rubik") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* rubik-500 - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/rubik-v9-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Rubik Medium"), local("Rubik-Medium"),
    url("./assets/fonts/rubik-v9-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./assets/fonts/rubik-v9-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("./assets/fonts/rubik-v9-latin-500.woff") format("woff"),
    /* Modern Browsers */
    url("./assets/fonts/rubik-v9-latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("./assets/fonts/rubik-v9-latin-500.svg#Rubik") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

// MAP SASS VAR FOR EASY DESIGN POLISHING TO CSS VARS / THEME VARS
@mixin setVars {
  // FIXED

  // THEMEABLE
  // header
  --kw-widget-header-background: #{$kw-header-background-color};
  --kw-widget-header-textcolor: #{$kw-header-text-color};

  --kw-messageTypes-userMessage-background: #{$kw-messagesTypes-userMessage-background};
  --kw-messageTypes-userMessage-textcolor: #{$kw-messagesTypes-userMessage-textcolor};

  --kw-messageTypes-textMessage-background: #{$kw-messagesTypes-textMessage-background};
  --kw-messageTypes-textMessage-textcolor: #{$kw-messagesTypes-textMessage-textcolor};

  --kw-messageTypes-card-background: #{$kw-messagesTypes-card-background};
  --kw-messageTypes-card-button-background: #{$kw-messagesTypes-card-button-background};
  --kw-messageTypes-card-button-textcolor: #{$kw-messagesTypes-card-button-textcolor};
  --kw-messageTypes-card-button-border: #{$kw-messagesTypes-card-button-border};

  --kw-messageTypes-carousel-background: #{$kw-messagesTypes-carousel-background};

  --kw-messagecontainer-background: #{$kw-messagecontainer-background};
  --kw-widget-widget_button-background: #{$kw-header-background-color};

  // variable for outside widget borders on desktop
  --kw-widget-global-borderradius-inner: #{$kw-widget-global-borderradius-inner};
  // variable for all inside elements like messages, buttons, cards (previously base border radius)
  --kw-widget-global-borderradius-outer: #{$kw-widget-global-borderradius-outer};
  // variable for ALL avatar images AND the widget button
  // (has to be one variable because they overlap in the teaser and fade into each other)
  --kw-widget-global-borderradius-shape: #{$kw-widget-global-borderradius-shape};

  --kw-widget-global-position-bottom: #{$kw-widget-global-position-bottom};
  --kw-widget-global-position-right: #{$kw-widget-global-position-right};

  --kw-widget-global-zindex: 9999;
}

:root {
  display: block;
  @include setVars();
}

/* 
:host{
  @include setVars()
} */

#knw_widget a:before {
  content: none;
}

#knw_widget {
  img {
    padding: 0;
  }
}

#knw_widget {

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  box-sizing: border-box;
  pointer-events: none;
  font: $kw-default-font;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: -webkit-fill-available;
  min-height: 120px;
  z-index: var(--kw-widget-global-zindex);

  overflow: hidden;

  @media (min-width: 501px) {
    padding: 20px;
  }

  @media (min-width: $kw-widget-breakpoint) {
    overflow: visible;
    height: 100%;

    // DOES NOT WORK IN EDGE
    @supports (-ms-ime-align: auto) {
      height: 100%;
    }
  }
}

.knw_widget_window {
  position: relative;
  pointer-events: all;
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 2000;
  background-color: $kw-global-background-messagecontainer;
  transition: bottom 0.5s ease-in-out, right 0.5s ease-in-out;

  @media (min-width: 480px) {
    border-top-left-radius: var(--kw-widget-global-borderradius-outer);
    border-top-right-radius: var(--kw-widget-global-borderradius-outer);
    border-bottom-right-radius: var(--kw-widget-global-borderradius-outer);
    border-bottom-left-radius: var(--kw-widget-global-borderradius-outer);

    @supports (-ms-ime-align: auto) {
      border-radius: var(--kw-widget-global-borderradius-outer);
    }

    // for some reason, this fixes the height problem in safari (100% being too high)
    box-shadow: 5px 5px 15px $kw-default-shadow-color-dark;
    min-height: 100%;
  }
}

// widgetbutton transitions

.widgetbutton-enter-from

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0.2;
  transform: scale(0);
}

.widgetbutton-enter-active {
  transition: opacity 0.4s, transform 0.4s cubic-bezier(0.1, 0.8, 0.3, 1.5);
  transform-origin: 30px 30px;
}

.widgetbutton-enter-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 1;
  transform: scale(1);
}

.widgetbutton-leave--from

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 1;
  transform: scale(1);
}

.widgetbutton-leave-active {
  // position: absolute;
  // min-height: calc(100% - 10px);
  transition: opacity 0.25s, transform 0.5s;
  transform-origin: 50px 50px;

  @media (min-width: $kw-widget-breakpoint) {
    transform-origin: 30px 30px;
  }
}

.widgetbutton-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: scale(0);
}

// window transitions

.window-enter-from

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: scale(0.9);
}

.window-enter-active {
  transition: opacity 0.25s, transform 0.3s cubic-bezier(0.1, 0.8, 0.3, 2);
  transform-origin: bottom right;
  z-index: 10000;
  // min-height: calc(100% + 60px);
}

.window-enter-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 1;
  transform: scale(1);
}

.window-leave-from

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 1;
  transform: scale(1);
}

.window-leave-active {
  // position: absolute;
  // min-height: calc(100% + 60px);
  transition: opacity 0.25s, transform 0.25s ease-in-out;
  transform-origin: calc(100% - 30px) calc(100% - 30px);
}

.window-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: scale(0);
}

// teaser transitions

// Button fade to background and bottom
.teaser-hide-enter-from

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: rotate(0deg);
  transform-origin: bottom right;
}

.teaser-hide-enter-active {
  transition: all $kw-default-transition-speed;
}

.teaser-hide-enter-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 1;
  transform: rotate(0deg);
  transform-origin: bottom right;
}

// TEASER LEAVE
.teaser-hide-leave-from {
  opacity: 1;
  transform-origin: bottom right;

  .kw-teaser-closebutton-wrapper {
    opacity: 1;
  }

  .avatar-shadow-wrapper {
    opacity: 0;

    .avatar {
      transform: scale(1);
      opacity: 1;
    }
  }

  .kw-teaser-message {
    opacity: 1;
    transform: scale(1);
  }

  .kw-teaser-quickaction-wrapper {
    opacity: 1;
    transform: scale(1);
  }
}

// TEASER LEAVE ACTIVE
.teaser-hide-leave-active {
  transition: all 0.5s;
  transition-delay: 0s;

  .kw-teaser-closebutton-wrapper {
    transition: all 0.25s;
    transition-delay: 0s;
  }

  .avatar-shadow-wrapper {
    transition: all 0.5s;
    transition-delay: 0.15s;

    .avatar {
      transition: all 0.4s;
      transition-delay: 0.15s;
    }
  }

  .kw-teaser-message {
    transition: all 0.25s;
    transition-delay: 0s;
    transform-origin: 100% 100%;
  }

  .kw-teaser-quickaction-wrapper {
    transition: all 0.25s;
    transition-delay: 0.1s;
    transform-origin: 100% 0%;
  }
}

// TEASER LEAVE TO
.teaser-hide-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform-origin: bottom right;

  .kw-teaser-closebutton-wrapper {
    opacity: 0;
  }

  .avatar-shadow-wrapper {
    opacity: 0;

    .avatar {
      transform: scale(0);
      opacity: 0;
    }
  }

  .kw-teaser-message {
    opacity: 0;
    transform: scale(0);
  }

  .kw-teaser-quickaction-wrapper {
    opacity: 0;
    transform: scale(0);
  }
}
</style>
