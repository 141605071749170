<template>
  <div
    class="knw_widget_inputbar_toast"
    :class="[{ showToast: this.showToast }, connectionState]"
  >{{ toastMessage }}</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useWidgetStore } from '@/store/widget'

export default {
  name: 'toast',
  computed: {
    ...mapStores(useWidgetStore),
    showToast() {
      return this.widgetStore.widget.connectionState.state !== '';
    },
    connectionState() {
      return this.widgetStore.widget.connectionState.state;
    },
    toastMessage() {
      return this.widgetStore.widget.connectionState.message;
    },
  },
};
</script>

<style lang="scss">
.knw_widget_inputbar_toast {
  position: relative;
  width: 100%;
  height: 0px;
  top: 0;
  opacity: 1;
  padding-top: 1px;
  line-height: 26px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: white;
  overflow: hidden;
  z-index: 50;
  transition: height 0.5s ease-in-out, background-color 0.25s linear 0.25s;
  // transition: all 0.5s cubic-bezier(0.72, -0.77, 0.39, 2.02);
  pointer-events: none;

  &.online {
    background: #3ec71d;
    transition: height 0.5s ease-in-out, background-color 0.25s linear;
  }

  &.warning {
    background: orange;
    transition: height 0.5s ease-in-out, background-color 0.25s linear;
  }

  &.offline {
    background: #e40000;
    transition: height 0.5s ease-in-out, background-color 0.25s linear;
  }

  &.showToast {
    pointer-events: all;
    opacity: 1;
    height: 28px;
  }
}
</style>
