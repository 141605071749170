import { conversationInfo, textMessage } from './common/messages';
import botService from '@/services/bot';

const context = [];
let store = undefined;

const initApi = (widgetStore) => {
  store = widgetStore
}

const api = {
  // tesing for avatar logic / conversation msg and operator functions
  async dev() {
    store.addOperator({
      opid: 'livechat',
      name: 'test',
      titel: 'test title',
      avatar_url: 'https://media-dev.botcast.ai/1627480054497_287jpeg',
    });

    store.setOperator('livechat');
    store.addMessage(await conversationInfo('Livechat', Date.now()))
    store.addMessage(await textMessage({
      timestamp: Date.now(),
      text: 'Test',
      source: 'agent',
      operator: 'livechat',
    }));
  },
  addContext: (ctx) => {
    botService.addContext(ctx);
  },
  getContext: () => {
    console.log(botService.getContext());
    return botService.getContext();
  },
  toggle: () => {
    store.toggleWidget()
  },
  toggle_Widget: () => {
    store.toggleWidget()
  },
  close: () => {
    store.closeWidget()
  },
  open: () => {
    store.openWidget()
  },
  typing: () => {
    store.setIsTyping(true)
  },
  alwaysOpen: () => {
    store.setWidgetOpenBehaviour('always')
    store.openWidget()
  },
  setThemeOption: (theme, options) => {
    // TODO is options still used?
    /* if (options) {
      bus.command(setThemeOptions(options));
    }
    bus.command(setTheme({ components: theme })); */
    store.setTheme({ ...theme })
  },
  setWidgetzIndex: ({ index }) => {
    if (!index) return
    store.setZIndex(index);
  },
  showWidget() {
    store.setVisible(true)
  },
  hideWidget() {
    store.setVisible(false)
  },
  setPosition: ({
    bottom, right, unit = 'px',
  }) => {
    const error = () => { throw new Error('please no nullish') }
    if (unit === '%') {
      console.warn('please use units like rem or em as realtive units, % is unfortunately not reliable due to technical issues')
    }
    try {
      bottom ?? error()
      right ?? error()
      store.setPosition(bottom + unit, right + unit)
    } catch (error) {
      console.error('Please provide proper parameter for left, right and unit')
    }
  },
  initLivechat: async ({ text = 'livechat Takeover' }) => {
    await botService.initLivechat({ text });
  },
  getLivechatAvailable: async ({ text = 'livechat Takeover' }) => {
    await botService.isLivechatAvailable({ text });
  },
  startLivechat: async ({ text = 'livechat Takeover' }) => {
    await botService.startLivechat({ text });
  },

  stopLivechat: async ({ text = 'livechat Takeover' }) => {
    await botService.stopLivechat({ text });
  },
  teaser: ({
    showTeaser = true, showAvatar = true, message = '', quickActions = [], timeout = 0, forceShow = false,
  }) => {
    /* console.log('API', bot.getJWTToken()); */
    store.setTeaser({ showTeaser, showAvatar, message, quickActions, timeout, forceShow, })
  },
  // moin.sendIntent({ intent: "faq_kick_mich_thema", parameters: {confidence: 1}})
  // text will be ignored
  sendIntent: ({ intent, confidence }) => {
    botService.sendIntent({
      intent,
      parameters: {
        confidence: confidence || 1.0,
      },
      api: true,
    });
  },
  clearQR: () => {
    store.setQuickReplies([])
    // bus.command(quickReplies([], Date.now()));
  },
};

export default {
  funcs: api,
  initApi
};
