<template>
  <div
    v-if="getQR.length > 0"
    ref="knw_widget_qrlist_wrapper"
    class="knw_widget_qrlist_wrapper"
    :style="{ height: getWrapperHeight }"
  >
    <div ref="knw_widget_qrlist" class="knw_widget_qrlist">
      <!-- TO DO -> PROPPER KEY -->
      <button
        class="knw_widget_qr"
        v-for="qr in getQR"
        :key="qr.text"
        @click="handleQuickReply(qr.intent.name, qr.text, qr.intent.parameters)"
      >{{ qr.text }}</button>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useWidgetStore } from '@/store/widget'
import { signal, SIGNAL_TYPES } from '../../common/utils';

export default {
  name: 'quickReplies',
  props: ['quickReplies'],
  data() {
    return {
      // initial height
      dynamicHeight: 0,
      clicked: false,
    };
  },
  computed: {
    ...mapStores(useWidgetStore),
    getQR() {
      return this.quickReplies;
    },
    getWrapperHeight() {
      return `${this.dynamicHeight}px`;
    },
  },
  mounted() {
    // read height and set a local state var and bind it to the style Element (line 2 and 28)
    this.dynamicHeight = this.$refs.knw_widget_qrlist.clientHeight;
  },
  updated() {
    this.dynamicHeight = this.$refs.knw_widget_qrlist.clientHeight;
  },
  methods: {
    handleQuickReply(intent, text, parameters) {
      if (this.clicked) return;
      this.clicked = true;
      // reset click guard because we resuse the component
      // and we need to reset the state for next qr
      setTimeout(() => { this.clicked = false; }, 500);
      this.widgetStore.intent({
        intent,
        text,
        parameters
      })
      signal(SIGNAL_TYPES.CLICK, { source: 'quickreplies', text })
    },
  },
};
</script>

<style lang="scss">
.knw_widget_qrlist_wrapper {
  overflow: hidden;
  .knw_widget_qrlist {
    // overflow: auto;
    z-index: 90;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    padding: 4px 8px;
    height: auto;
    min-height: 48px;
    position: relative;
    margin-bottom: 10px;
  }
}

.knw_widget_qr {
  @include qr_pill;
  text-transform: none !important;
}
</style>
