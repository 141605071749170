<template>
  <div class="kw-conversation-message">
    <div class="kw-conversation-message-divider">
      <div>
        <IconTakeover />
      </div>
    </div>
    <p>{{message.data.text}}</p>
  </div>
</template>

<script>
import IconTakeover from '@/svg/IconTakeover.vue';

export default {
  name: 'CoversationMessage',
  props: ['message'],
  components: {
    IconTakeover,
  },
};
</script>

<style lang="scss">
.kw-conversation-message{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 40px 0;

  .kw-conversation-message-divider{
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
      > div {
        background: #aeaeae;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    svg{
      fill: #646464;
    }
    &:before{
      position: absolute;
      left: 0;
      right: 0;
      top: 9px;
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-image: linear-gradient(90deg, #999, #999 75%, transparent 75%, transparent 100%);
      background-size: 10px 1px;
      border: none;

    }
  }
  p{
    max-width: 60%;
    margin: 0 auto;
    line-height: 1;
    color: #444;
    text-align: center;
  }
}
</style>
