<template>
  <transition name="typing">
    <div class="knw_typingIndicator" v-show="isTyping">
      <div id="wave">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'TypingIndicator',
  props: ['typing'],
  computed: {
    isTyping() {
      return this.typing;
    },
  },
};

</script>

<style lang="scss" scoped>
.knw_typingIndicator {
  position: absolute;
  bottom: 10px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-left: 57px;
  border-radius: var(--kw-widget-global-borderradius-inner);
  background: transparent;
  padding: 12px 10px 2px 0px;
}

.wave {
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: inline;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
  background: #999999;
  animation: wave 1.3s linear infinite;

  &:nth-child(2) {
    animation-delay: -1.1s;
  }

  &:nth-child(3) {
    animation-delay: -0.9s;
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-9px);
  }
}

// typing transitions

.typing-enter-from {
  opacity: 0;
}
.typing-enter-active {
  transition: opacity $kw-default-transition-speed-fast linear;
}
.typing-enter-to {
  opacity: 1;
  transform: translateX(0) scaleY(1);
}
.typing-leave-from {
}
.typing-leave-active {
  transition: opacity $kw-default-transition-speed-fast linear;
}
.typing-leave-to {
  opacity: 0;
  // transform: translateX(-50px);
  transform-origin: center top;
}

.typing-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}
</style>
