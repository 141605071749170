import bot from '@/services/bot';
import {
  setOperator,
  addOperator
} from '@/common/commands';
import {
  textMessage,
  conversationInfo
} from '@/common/messages';


class Server2Server {
  constructor({
    payload,
  }) {
    console.log('in server2server constructor');
  }

  initLiveChat() {

    console.log('in server2server initLivechat');

    return {
      success: true,
    };
  }


  async startLivechat({
    text,
  }) {

    console.log('in server2server startLivechat');
    //this.setOperator();
    return {
      success: true,
    };
  }

  async setOperator(data) {
    console.log("setOperator", data);
    if (data) {


      let agentData = {
        opid: 'livechat',
        name: data.display_name || 'Livechat Agent',
        titel: data.title || '',
        avatar_url: data.avatar_url || '',
      };

      //case if avatar is send as base64 encoded
      if (data.avatar_base64) {
        agentData.avatar_url = `data:image/png;base64,${data.avatar_base64}`
      }


      bot.livechatCommand(addOperator(agentData));
      bot.livechatCommand(setOperator('livechat'));
    }

  }

  stopLivechat() {

    console.log('in server2server stopLivechat');
    return {
      success: false,
    };
  }

  sendMessage() {
    return true;
  }

}

export default Server2Server;
