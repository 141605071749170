export const isTyping = (value) => {
  return {
    type: 'cmd',
    cmd: 'typing',
    action_value: value,
  };
};

export const quickReplies = (qrs, time) => {
  return {
    type: 'cmd',
    cmd: 'qr',
    time,
    timestamp: time,
    messageType: 'QuickReplies',
    qr: qrs,
    source: 'bot',
  };
};

export const setHistoy = (history) => {
  return {
    type: 'cmd',
    cmd: 'setHistoy',
    history,
  };
};

export const updateHistoy = (history) => {
  return {
    type: 'cmd',
    cmd: 'updateHistoy',
    history,
  };
};

export const setConnectionState = ({ state = 'online', timeout, message = 'default msg' }) => {
  return {
    type: 'cmd',
    cmd: 'setConnectionState',
    state,
    message,
    timeout,
  };
};

export const setOperator = (opid) => {
  return {
    type: 'cmd',
    cmd: 'setOperator',
    data: {
      opid,
    },
  };
};

export const addOperator = ({
  opid = 'default',
  name = 'default name',
  titel = 'default titel',
  avatar_url = 'https://gitlab.knowhere-media.de/uploads/-/system/user/avatar/23/avatar.png?width=90',
}) => {
  return {
    type: 'cmd',
    cmd: 'addOperator',
    data: {
      opid,
      name,
      titel,
      avatar_url,
    },
  };
};
