<template>
  <div :style="getGlobalTheme" class="knw_widget_button_wrapper" @click="onClick">
    <div class="knw_widget_button">
      <transition name="buttonfade" mode="out-in">
        <IconChatBubble />
      </transition>
      <transition name="kw-avatar-transition">
        <transition name="kw-avatar-wrapper" appear>
          <div class="avatar-wrapper" v-if="buttonAvatarOverlay">
            <Avatar :image="getDefaultAvatar" width="64px" height="64px" shadow="true" />
          </div>
        </transition>
      </transition>
    </div>
  </div>
</template>
<script>
import IconChatBubble from "../../svg/IconChatBubble.vue";
import Avatar from "../Avatar.vue";
import { mapStores, mapState } from "pinia";
import { useWidgetStore } from "../../store/widget";
import { setStyles } from "../../common/utils";
export default {
  name: "portalbutton",
  props: ["css", "frame"],
  components: {
    IconChatBubble,
    Avatar
  },
  computed: {
    ...mapStores(useWidgetStore),
    ...mapState(useWidgetStore, ["getDefaultAvatar", "getPosition", "getZIndex", "isWidgetOpen"]),
    buttonAvatarOverlay() {
      return this.widgetStore.widget?.teaser.showAvatar && this.widgetStore.widget?.showTeaser;
    },
    getGlobalTheme() {
      return this.widgetStore.getThemeCSSVars;
    }
  },
  beforeMount() {
    this.calcFrameStyles();
  },
  watch: {
    getPosition() {
      this.calcFrameStyles();
    },
    isWidgetOpen() {
      this.calcFrameStyles();
    },
    getZIndex() {
      this.calcFrameStyles();
    }
  },
  methods: {
    onClick() {
      this.widgetStore.openWidget();
    },
    calcFrameStyles() {
      const styles = {
        background: "transparent!important",
        //background: this.getDebug.active && this.getDebug?.boundingRect?.active && this.getDebug?.boundingRect?.button?.active ? `${this.getDebug.boundingRect.button.color}!important` : 'transparent',
        position: "absolute!important",
        border: "none!important",
        width: "85px!important",
        height: "85px!important",
        right: `${this.getPosition.button.right}!important`,
        bottom: `${this.getPosition.button.bottom}!important`,
        zIndex: `${this.getZIndex}!important`,
        pointerEvents: "all!important"
      };

      if (this.isWidgetOpen) {
        styles.width = "1px!important";
        styles.height = "1px!important";
        styles.bottom = "0px!important";
        styles.pointerEvents = "none!important";
      }
      setStyles(this.frame, styles);
    }
  }
};
</script>

<style lang="scss" scoped>
.knw_widget_button_wrapper {
  pointer-events: all;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  align-self: flex-end;
  z-index: 1000;

  &.animate {
    transition: bottom 0.5s ease-in-out, right 0.5s ease-in-out;
  }

  @media (min-width: $kw-widget-breakpoint) {
    /* <<< MEDIUM <<< */
    margin: 0;
    /* bottom: 40px;
    right: 40px; */
  }

  .knw_widget_button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
    align-self: flex-end;
    background: var(--kw-widget-widget_button-background);
    // border-radius: 50%;
    border-radius: var(--kw-widget-global-borderradius-shape);
    // border: 3px solid white;
    width: 60px;
    height: 60px;
    bottom: 0px;
    right: 0px;
    //@include default-dropshadow;
    box-shadow: 0px 0px 15px $kw-default-shadow-color-dark;
    @include default-hover-scale;

    svg {
      width: 34px;
      height: 32px;
      fill: white;
      fill: var(--kw-widget-widget_button-textcolor);
    }
  }
}

.buttonfade-enter-active,
.buttonfade-leave-active {
  transition: opacity 0.25s;
  transform: translateY(-400px);
}

.buttonfade-enter-from,
.buttonfade-leave-to

/* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
  transform: translateY(-400px);
}

.avatar-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  // border-radius: 50%;
  border-radius: var(--kw-widget-global-borderradius-shape);
  border: 3px solid white;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;

  @media (min-width: $kw-widget-breakpoint) {
    /* <<< MEDIUM <<< */
    margin: 0;
    bottom: 0;
  }

  @media (min-width: $kw-teaser-breakpoint) {
    /* <<< MEDIUM <<< */
    /* @include default-hover-scale; */
    right: 0px;
    // prevent colored background from bleeding through the edges
    transform: scale(1.01);
  }

  .avatar {
    background-color: #ffffff;
    position: relative;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
    }

    svg {
      border: none;
    }
  }
}

/* AVATAR WRAPPER TRANSITION */
.kw-avatar-wrapper-transition-enter-from {
  border-width: 0;
  border-radius: 50%;
}

.kw-avatar-wrapper-transition-enter-active {
  transition: border-width 0.25s linear;
}

.kw-avatar-wrapper-transition-enter-to {
  border-width: 3px;
  border-radius: 50%;
}

/* AVATAR TRANSITION */
.kw-avatar-transition-enter-from {
  opacity: 0;
  transform: scale(0.15);
  background-color: transparent;

  // transform: scale(3) translateY(-19px);
  .avatar-wrapper {
    border-width: 0;
    border-radius: 50%;
  }
}

.kw-avatar-transition-enter-active {
  transition: opacity 0.4s linear 0.1s, transform 0.5s cubic-bezier(0.1, 0.5, 0.3, 1.5) 0.1s,
    background-color 0.15s;

  // transform .5s ease-in-out .2s;
  .avatar-wrapper {
    transition: border-width 0.25s linear;
  }
}

.kw-avatar-transition-enter-to,
.kw-avatar-transition-leave-from {
  opacity: 1;
  transform: scale(1);
  background-color: #ffffff;
}

.kw-avatar-transition-leave-to {
  opacity: 0;
  // interfiers with the Avatar Transition. Not sure yet to work around that issue.
  //transform: scale(.15);
  background-color: transparent;
}

.kw-avatar-transition-leave-active {
  transition: opacity 0.4s linear 0.1s, transform 0.5s cubic-bezier(0.1, 0.5, 0.3, 1.5) 0.1s,
    background-color 0.15s;
}
</style>
